import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs/operators";
import { themeList, iTheme, iDesignTemplate, iDesignTemplateType, getDefaultThemeStructure, business_classic, custom } from '../interfaces/themes';
import { EntityService } from "./entity.service";
import { FileManagerService } from "./file-manager.service";
import { SettingsService } from "./settings.service";

@UntilDestroy()
@Injectable({
  providedIn: "root"
})
export class ThemeService {

  private _active: iTheme = business_classic;
  private _designTemplatesMap: {[key: number]: iDesignTemplate} = {};
  private _designTemplateTypesMap: {[key: number]: iDesignTemplateType} = {};
  private _currentTheme: iTheme;

  constructor(private _translateService: TranslateService,
              private _entityService: EntityService,
              private _fileManagerService: FileManagerService,
              private _settingsService: SettingsService) {
    this._currentTheme = Object.assign({}, getDefaultThemeStructure());
  }

  activateTheme(name: string, custom_content?: any): void {
    let theme: iTheme = JSON.parse(JSON.stringify(business_classic));
    if (name === 'custom') {
      theme.name = 'custom';
      theme.properties = Object.assign(theme.properties, custom_content);
    } else {
      for (let i = 0; i < themeList.length; i++) {
        if (themeList[i].name === name) {
          theme = themeList[i];
          break;
        }
      }
    }

    this.setActiveTheme(theme);
  }

  setActiveTheme(theme: iTheme): void {
    this._active = theme;
    Object.keys(this._active.properties).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        this._active.properties[property]
      );
    });
  }

  modifyCurrentTheme(footerTitle: string, footerText: string): void {
    this._currentTheme.properties['--footer-headline-content'] = footerTitle;
    this._currentTheme.properties['--footer-paragraph-content'] = footerText;
  }

  set designTemplatesMap(templatesMap: {[key: number]: iDesignTemplate}) {
    this._designTemplatesMap = templatesMap;
  }

  set designTemplateTypesMap(typesMap: {[key: number]: iDesignTemplateType}) {
    this._designTemplateTypesMap = typesMap;
  }

  get designTemplatesMap(): {[key: number]: iDesignTemplate} {
    return this._designTemplatesMap;
  }

  get designTemplateTypesMap(): {[key: number]: iDesignTemplateType} {
    return this._designTemplateTypesMap;
  }

  get themeMap() {
    return this._active;
  }

  get defaultTheme() {
    this._currentTheme = Object.assign({}, getDefaultThemeStructure());
    this._translateService.get('CUSTOM_DESIGN').subscribe(
      result => this.modifyCurrentTheme(result['title_footer_default'], result['text_footer_default'])
    );
    return this._currentTheme
  }

  findActiveTheme(): void {
    this._settingsService.loadedDataEmitter.pipe(
      filter(data => data !== null),
    ).subscribe(data => {
      const code = this.designTemplatesMap[data?.portal_to_content_design_template_id?.content_design_template_id]?.code;
      if (code === 'custom') {
        this._active = custom;
      }
      for (let i = 0; i < themeList.length; i++) {
        if (themeList[i].name === code) {
          this._active = themeList[i];
          break;
        }
      }
    });
  }
  // Admin login, registration, forgot pages images 
  setLoginRegForgotImgStyles(settingsDesign: any, isMobile: boolean): string[] {
    let backgroundImageUrl = '';
    let logoImageUrl = '';
    let defaultThemeLoginBackground = '/assets/images/background@2x.png';
    let defaultThemeLoginMobileBackground = '/assets/images/background@2x.png';
    let defaultThemeLoginLogo = '/assets/images/mentortools_logo_white.svg';
    if (settingsDesign) {
      this.findActiveTheme();
      let presetThemeLoginBackground = this.themeMap.properties['--img-login-desktop'];
      let presetThemeLoginMobileBackground = this.themeMap.properties['--img-login-mobile'];
      let presetThemeLoginLogo = this.themeMap.properties['--img-logo-login'];
      if (presetThemeLoginBackground) defaultThemeLoginBackground = presetThemeLoginBackground;
      if (presetThemeLoginMobileBackground) defaultThemeLoginMobileBackground = presetThemeLoginMobileBackground;
      if (presetThemeLoginLogo) defaultThemeLoginLogo = presetThemeLoginLogo;

      if (!isMobile) {
        if (settingsDesign.wallpaper_login_page) {
          backgroundImageUrl = this._fileManagerService.getImageSrc(settingsDesign.wallpaper_login_page);
        } else {
          backgroundImageUrl = defaultThemeLoginBackground;
        }
      } else {
        if (settingsDesign.wallpaper_login_and_registration_mobile) {
          backgroundImageUrl = this._fileManagerService.getImageSrc(settingsDesign.wallpaper_login_and_registration_mobile);
        } else {
          backgroundImageUrl = defaultThemeLoginMobileBackground;
        }
      }
      if (settingsDesign.big_logo) {
        logoImageUrl = this._fileManagerService.getImageSrc(settingsDesign.big_logo);
      } else {
        logoImageUrl = defaultThemeLoginLogo;
      }
    } else {
      backgroundImageUrl = '/assets/images/background@2x.png';
      logoImageUrl = '/assets/images/mentortools_logo_white.svg';
    }
    return [backgroundImageUrl, logoImageUrl];
  }

}
