import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class LangPaginatorIntl {
  constructor(private _translateService: TranslateService) {
  }

  getlangPaginatorIntl(): MatPaginatorIntl {
    let paginatorIntl = new MatPaginatorIntl();
    this.setLanguage(paginatorIntl);
    // Change paginator label after lang changing
    this._translateService.onLangChange.subscribe(()=>{
      this.setLanguage(paginatorIntl);
    })
    return paginatorIntl;
  }

  setLanguage(paginatorIntl: MatPaginatorIntl): void {
    this._translateService.get('MAT_PAGINATOR').subscribe(
      result => paginatorIntl.itemsPerPageLabel = result.items_per_page_label+':'
    );
  }
}