import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

export class LoaderInterceptor implements HttpInterceptor {

    private _requestCounter = 0;

    private _isStartInterceptor = false;

    private _loaderWorked = false;
    
    private _timeoutId: NodeJS.Timeout;

    constructor() { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (this._loaderWorked) {
            return next.handle(request);
        }

        if (!this._isStartInterceptor && !this.checkOnStartRequest(request)) {
            return next.handle(request);
        }

        clearTimeout(this._timeoutId);
        this._requestCounter++;

        return next.handle(request).pipe(
            finalize(() => {
                this._requestCounter--;
                
                if (this._requestCounter === 0) {
                    this._timeoutId = setTimeout(() => {
                        this.deleteLoaderFromDOM();
                        this._loaderWorked = true;
                    }, 100);
                }
            })
        )
    }

    checkOnStartRequest(request: HttpRequest<unknown>): boolean {
        if (request.url.includes('auth/prolongate')) {
            this._isStartInterceptor = true;
        }

        return this._isStartInterceptor;
    }

    deleteLoaderFromDOM() {
        document.querySelector('.start-loader__container')?.remove();
    }
}