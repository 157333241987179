import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/internal/operators';
import { ActivatedRoute, Router } from '@angular/router';
// import * as Sentry from '@sentry/angular';
import { UserService } from '../../../services/user/user.service';
import { ServerSideRenderingService } from '@coreservices/server-side-rendering.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(
    private _router: Router,
    private _ssr: ServerSideRenderingService,
    private _userService: UserService,
    private _activateRoute: ActivatedRoute
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this._alertService.showError('activation', 'activation');
    this._activateRoute.queryParamMap.subscribe(params => {
      const ref = params.get('ref');
      
      if (ref && localStorage.getItem('ref') === null) {
        localStorage.setItem('ref', ref);
      }
    });
    
    if(this._ssr.isBrowser) {
      return next.handle(req).pipe(tap(
        // Event
        event => {
          if (event instanceof HttpResponse) {
          }
        },
        // Error
        error => {
            this.handleError(req, error);
        },
        // Complite
        () => {
        }));
    }
  }

  handleError(req, error): Observable<never> {
    if (typeof error?.error?.error === 'string' && error?.error?.error?.includes('No Portal found')) {
      this._router.navigate(['/not-found']);
    }

    if (req.url.includes('m3u8') || req.url.includes('prolongate') && (error?.status === 401 || error?.status === 403)) {
      return;
    }

    if(typeof error?.error?.error === 'string' && error?.error?.error?.includes('no_quiz_versions')) {
      return;
    }

    let errorMessage = '';
    if (error.error instanceof HttpErrorResponse) {
      // client-side error
      errorMessage = `>>> CLIENT SIDE ERROR\nMessage: ${error.message}`;
    } else {
      // server-side error
      switch (error.status) {
        case 400:
          errorMessage = 'Bad Request.';
          break;
        case 401:
          errorMessage = 'You need to log in to do this action.';
          break;
        case 403:
          errorMessage = 'You do not have permission to access the requested resource.';
          break;
        case 404:
          errorMessage = 'The requested resource does not exist.';
          break;
        case 412:
          errorMessage = 'Precondition Failed.';
          break;
        case 422:
          errorMessage = 'Validation Error!';
          break;
        case 500:
          errorMessage = 'Internal Server Error.';
          break;
        case 503:
          errorMessage = 'The requested service is not available.';
          break;
        default:
          errorMessage = 'Something went wrong!';
      }
      errorMessage = `>>> SERVER SIDE ERROR\nCode: ${error.status}\nURL: ${error.url}\nMessage: ${errorMessage}`;
    }

    console.error(errorMessage);

    if (error.status === 401 && !req.url.includes('session/sso')) {
      this._userService.deleteClient();
      this._router.navigate(['/login']);
      // alert('Your session has expired. Please log in');
      // return;
    }

    // this.sentryTransaction(req, error);
    return throwError(errorMessage);
  }

  // sentryTransaction(req, error): void {
  //   const scope = new Sentry.Scope();
  //   scope.setTransactionName('Request error');
  //   if (this._userService.client) {
  //     scope.setUser(this._userService.client);
  //   }
  //   scope.setTag('locale', this._translateService.currentLang);
  //   scope.setContext('HttpRequest', req);
  //   scope.setContext('HttpErrorResponse', error);
  //   Sentry.captureException(new Error(JSON.stringify(req.urlWithParams)), () => scope);
  // }
}
