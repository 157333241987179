import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieBannerComponent } from './cookie-banner.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@corepipes/pipes.module';



@NgModule({
  declarations: [CookieBannerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    PipesModule
  ],
  exports: [CookieBannerComponent]
})
export class CookieBannerModule { }
