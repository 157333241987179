import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user/user.service';

@Injectable()
export class CommunityLinkGuard implements CanActivate {

  constructor(private _router: Router,
              private _userService: UserService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if ((window.location.href.includes('topic') || window.location.href.includes('thread')) && (window.location.href.includes('customer') || window.location.href.includes('customer-new'))) {
      localStorage.setItem('communityUrl', state.url);
    }
    return true;
  }
}
