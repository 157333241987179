import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user/user.service';
import { EntityService } from '../services/entity.service';
import { CookieService } from 'ngx-cookie-service';
import { ServerSideRenderingService } from '@coreservices/server-side-rendering.service';

@Injectable()
export class SupportGuard implements CanActivate {
  constructor(private _router: Router,
              private _userService: UserService,
              private _entityService: EntityService,
              private _cookieService: CookieService,
              private _ssr: ServerSideRenderingService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if(this._ssr.isBrowser) {
      return new Promise<boolean>((resolve) => {
        if (this._cookieService.check('abo_domain') && this._cookieService.check('abo_hash')) {
          if (window.location.origin !== this._cookieService.get('abo_domain')) {
            window.location.href = `${this._cookieService.get('abo_domain')}/admin?hilfe=${this._cookieService.get('abo_hash')}`;
          }
        }

        this._entityService.prolongate().subscribe(
          (success_response) => {
            if (this._userService.getRole() === 'content_creator') {
              this._router
                .navigate(['/admin/statistics'])
                .finally(() => window['Intercom']('show'));
              resolve(false);
            }
            resolve(true);
          },
          (errorResponse) => {
            if (errorResponse.status !== 401) {
              // Not Unauthorized
              throw new Error(errorResponse.statusText);
            } else {              
              this._userService.deleteClient();
            }
            resolve(true);
          }
        );
      });
    }
  }
}
