import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ServerSideRenderingService } from '@coreservices/server-side-rendering.service';
import { Observable } from 'rxjs';
import { UserService } from '../services/user/user.service';

@Injectable()
export class TrialEndGuard implements CanActivate {

  constructor(private _router: Router,
              private _userService: UserService,
              private _ssr: ServerSideRenderingService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(this._ssr.isBrowser) {
        return new Promise<boolean>(resolve => {
          if (this._userService.getPortalType() === 'trial_ended') {
            resolve(true);
          } else {
            this._router.navigate(['/admin']);
            resolve(false);
          }
        });
      }
  }
}
