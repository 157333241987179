import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CoreModule } from './app/_core/core.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";
import { BrowserTracing, Integrations } from "@sentry/tracing";
import '@angular/common/locales/global/de';
import '@angular/common/locales/global/en';
import '@angular/common/locales/global/sk';
import '@angular/common/locales/global/hu';
import '@angular/common/locales/global/mk';
import '@angular/common/locales/global/cs';
// import * as Sentry from "@sentry/angular";
// import { Integrations } from "@sentry/tracing";
// Codemirror
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/markdown/markdown';

Sentry.init({
  dsn: "https://b26669553a234201ae044b4b3a9befd1@sentry.mentortools.com/2",
  environment: environment.type,
  release: "dev-release",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    // new BrowserTracing({
    // //   tracePropagationTargets: [window.location.hostname],
    //   tracePropagationTargets: ["localhost", "https://yourserver.io/api"],
    //   new Integrations.BrowserTracing(),
    // }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// Sentry.init({
//   dsn: "https://c559963423be41e095799433e78e8563@sentry.mentortools.com/4" ,
//   environment: environment.type,
//   integrations: [
//     // Registers and configures the Tracing integration,
//     // which automatically instruments your application to monitor its
//     // performance, including custom Angular routing instrumentation
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(CoreModule)
    .catch(err => {
      console.error(err);
      if ('serviceWorker' in navigator && environment.production) {
        navigator.serviceWorker.register('./ngsw-worker.js');
      }
    }
    );
});
