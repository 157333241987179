import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from './user/user.service';
import { TranslateService } from '@ngx-translate/core';

declare var gtag: Function;

@Injectable()
export class GoogleAnalyticsService {

  constructor(
    public router: Router,
    public userService: UserService,
    public _translateService: TranslateService,
    ) {

    this.router.events.subscribe(event => {
      try {
        if (typeof gtag === 'function') {
          if (event instanceof NavigationEnd) {
            this.emitEvent('GA', 'view page', event.urlAfterRedirects);
          }
        }
      } catch (e) {
        console.error(e);
      }
    });

  }

  /**
   * Emit google analytics event
   * Fire event example:
   * this.emitEvent("testCategory", "testAction", "testLabel", 10);
   * @param {string} eventCategory
   * @param {string} eventAction
   * @param {string} eventLabel
   * @param {number} eventValue
   */
  emitEvent(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null): void {
    if (typeof gtag === 'function') {
      gtag('event', 'page view', {
        event_category: eventCategory,
        event_label: eventLabel,
        event_action: eventAction,
        event_value: eventValue
      });
    }
  }

  addCoockieTag(trackingID: string, gaAnonIsAppend: boolean): void {
    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute("id", "gtag-script");
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingID}`);

    const gaScript2 = document.createElement('script');
    gaScript2.setAttribute("id", "ga-script");
    gaScript2.innerText = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag(\'js\', new Date());
      gtag(\'config\', \'${trackingID}\', {\'anonymize_ip\':${gaAnonIsAppend}\});
    `;

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  }

  addTag(gtmID: string): void {
    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute("id", "gtag-script");
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${gtmID}`);

    const gaScript2 = document.createElement('script');
    gaScript2.setAttribute("id", "ga-script");
    gaScript2.innerText = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag(\'js\', new Date());
      gtag(\'config\', \'${gtmID}\');`;

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);

  }

  translateKey = this.userService?.client?.portal_member?.business_model
  currentLang = this._translateService?.currentLang;
  
  confirmEmailGoogleTag(): void {
    let arg = {
      event: "confirm_email",
      business_model: this.translateKey,
      lang: this.currentLang,
      test_version: "free",
    };
    window["dataLayer"] = window["dataLayer"] || [];
    window["dataLayer"].push(arg);
  }

  appendGTM(gtmID : string): void {
    try {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src =
              'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${gtmID}');
      `;
      document.head.appendChild(script);
    } catch (ex) {
      console.error('Error appending gtm');
      console.error(ex);
    }
  }

  appendGTMNoScript(gtmID : string): void {
    try {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id='${gtmID}'"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.head.appendChild(noscript);
    } catch (ex) {
      console.error('Error appending gtm');
      console.error(ex);
    }
  }
  
  clearStatisticScripts(): void {
    document.getElementById('gtag-script')?.remove();
    document.getElementById('ga-script')?.remove();
    document.querySelector('script[src="https://www.google-analytics.com/analytics.js"]')?.remove();
  }

}
