import { Injectable } from '@angular/core';
import { iGdprAcceptation, iClient, iRole, iPortalType, iUserStatus, iPortalMember, iPortal } from '../../interfaces/user';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServerSideRenderingService } from '@coreservices/server-side-rendering.service';
import { MobileAppService } from '@coreservices/mobile-app.service';
import { TranslateService } from '@ngx-translate/core';
import { iComment, iCommunitySettings, iCreator, iThread } from '@coreinterfaces/community';

export enum registrationType{
	single = 1,
	double = 2
}

@Injectable()
export class UserService {

  constructor(private _cookieService: CookieService,
              private _serverSideRenderingService: ServerSideRenderingService,
              private _translateService: TranslateService) { }

  protected CLIENT: iClient | null = null;
  public gdprAcceptation: iGdprAcceptation;
  protected PORTAL: iPortal | null = null;
  fullPortal: iPortal;

  roles: iRole[] = [];
  portalTypes: iPortalType[] = []
  statuses: iUserStatus[] = []

  private trialPortalTime = new BehaviorSubject<number>(null);
  activatedUser = new BehaviorSubject<iPortalMember>(null);

  /*****************************************************  GETTERS  ****************************************************/

  getRole(): any {
    return this.roles.filter(role => role.id === this.client?.portal_member.role_id)[0]?.name;
  }

  getPortalId(): number {
    return this.client.portal.id;
  }

  getRoleById(roleId: number): string {
    return this.roles.filter(role => role.id === roleId)[0]['name'];
  }

  getStatusById(id: number): string {
    return this.statuses.filter(role => role.id === id)[0]['name'];
  }

  getPortalType(): string {
    return this.portalTypes.filter(role => role.id === this.client?.portal.type_id)[0]?.name;
  }

  getPortalInfo(): iPortal {
    return this.fullPortal;
  }

  getUserName( creator , threads_anonym_posting_is_enabled: boolean,
     display_name_setting_code: string , is_anonym = false ): string {
    let userName = ''
    if(threads_anonym_posting_is_enabled && is_anonym){
        userName = this._translateService.instant('COMMUNITY_PAGE.anonymous_account');
    }
    if(!threads_anonym_posting_is_enabled || !is_anonym){
      switch(display_name_setting_code){
        case 'first_and_last_name': {
          if(creator.first_name || creator.last_name){
            userName = creator?.first_name + ' ' +  creator?.last_name
          }else{
             userName = this._translateService.instant('COMMON.community.community_member')
          }
          break;
        }
        case 'only_first_name':  {
          if(creator.first_name){
            userName = creator?.first_name
          }else{
             userName = this._translateService.instant('COMMON.community.community_member')
          }
          break;
        }
        case 'custom_name':  {
          if(creator.nickname){
            userName = creator?.nickname
          }else if(creator.first_name || creator.last_name){
            userName = creator?.first_name + ' ' +  creator?.last_name
          }else{
             userName = this._translateService.instant('COMMON.community.community_member')
          }
          break;
        }
      }
    }
    return userName
}

  get client(): iClient {
    if (this._serverSideRenderingService.isBrowser) {
      if (this.CLIENT) {
        return this.CLIENT;
      } else if (localStorage.getItem('client')) {
        return JSON.parse(localStorage.getItem('client'));
      } else {
        return null;
      }
    }
  }

  get portal(): iPortal {
    if (this._serverSideRenderingService.isBrowser) {
      if (!!this.PORTAL) {
        return this.PORTAL;
      } else if (localStorage.getItem('client')) {
        return JSON.parse(localStorage.getItem('client')).portal;
      } else {
        return null;
      }
    }
  }

  get getGdprAcceptation(): iGdprAcceptation {
    if (this._serverSideRenderingService.isBrowser) {
      if (JSON.parse(localStorage.getItem('gdpr_acceptation'))) {
        return JSON.parse(localStorage.getItem('gdpr_acceptation'));
      } else {
        return null;
      }
    }
  }

  get token(): string | null {
    if (this._serverSideRenderingService.isBrowser) {
      if (window.location.search.substr(1).length > 0) {
        let hashParam: any = window.location.search.substr(1).split('=');
        if (hashParam.length > 0 && hashParam[0] === 'hash') return hashParam[1].split('&')[0];
        if (hashParam.length > 0 && hashParam[0] === 'hilfe') return hashParam[1].split('&')[0];
      }
      if (this.CLIENT) {
        return this.CLIENT.token;
      } else if (localStorage.getItem('client')) {
        return JSON.parse(localStorage.getItem('client')).token;
      } else {
        return null;
      }
    }
  }

  get portal_member_id(): number | null {
    if (this._serverSideRenderingService.isBrowser) {
      if (this.CLIENT) {
        return this.CLIENT.portal_member.id;
      } else if (localStorage.getItem('client')) {
        return JSON.parse(localStorage.getItem('client')).portal_member.id;
      } else {
        return null;
      }
    }
  }

  get portal_id(): number | null {
    if (this._serverSideRenderingService.isBrowser) {
      if (this.CLIENT) {
        return this.CLIENT.portal.id;
      } else if (localStorage.getItem('client')) {
        return JSON.parse(localStorage.getItem('client')).portal.id;
      } else {
        return null;
      }
    }
  }

  get portal_lang_code(): string {
    if(this.client.portal.language_id === 1) {
      return 'en';
    } else if (this.client.portal.language_id === 2) {
      return 'de';
    } else if (this.client.portal.language_id === 3) {
      return 'sk';
    } else if (this.client.portal.language_id === 4) {
      return 'hu';
    } else if (this.client.portal.language_id === 5) {
      return 'mk';
    } else if (this.client.portal.language_id === 6) {
      return 'cz';
    }
  }

  getTrialPortalTime(): Observable<number | null> {
    return this.trialPortalTime;
  }

  /*****************************************************  SETTERS  ****************************************************/

  setTrialPortalTime(trialTime: number): void {
    this.trialPortalTime.next(trialTime);
  }

  setClient(clientObj: iClient, remember: boolean = true): void {
    this.CLIENT = clientObj;
    if (remember) localStorage.setItem('client', JSON.stringify(clientObj));
    this.setCookie(clientObj);
    this.setPortal(clientObj.portal);

    MobileAppService.setIsMobileApp();
  }

  setPortal(portal: iPortal): void {
    this.fullPortal = portal[0];
    if ((portal as any)?.length === 0) {
      this.PORTAL = null;
    }
    else if(portal[0]) {
      this.PORTAL = portal[0];
    }  else {
      this.PORTAL = portal;
    }
  }

  setCookie(clientObj: iClient): void {
    if (this.getRole() === 'customer') this.deleteCookie();
    if (this.getRole() === 'content_creator') {
      const date: any = new Date(Date.now() + (24 * 60 * 60 * 1000));
      if (!environment.production) { // for local test - do not remove
        this._cookieService.set('abo_hash', clientObj.token, date, '/', 'abobot.loc');
        this._cookieService.set('abo_domain', `${clientObj.portal.domain}.loc:4200`, date, '/', 'abobot.loc');
      } else {
        this._cookieService.set('abo_hash', clientObj.token, date, '/', 'mentortools.com', true);
        this._cookieService.set('abo_domain', `${clientObj.portal.domain}`, date, '/', 'mentortools.com', true);
      }
    }
  }

  setGdprAcceptation(isAccept: boolean, time: number, isMarketing: boolean, isStatistics: boolean): void {
    this.gdprAcceptation = {is_accept: isAccept, accepted_at_epoch: time, is_marketing: isMarketing, is_statistics: isStatistics};
    localStorage.setItem('gdpr_acceptation', JSON.stringify(this.gdprAcceptation));
  }

  setRoles(roles: iRole[]): void {
    this.roles = roles;
  }

  setStatuses(statuses: iUserStatus[]): void {
    this.statuses = statuses;
  }

  setPortalTypes(types: iPortalType[]): void {
    this.portalTypes = types;
  }

  /*****************************************************  ACTIONS  ****************************************************/

  /* Clean current user object in memory */
  deleteClient(): void {
    if (this.getRole() === 'content_creator') this.deleteCookie();
    this.CLIENT = null;
    if (this._serverSideRenderingService.isBrowser) {
      sessionStorage.removeItem('inactiveCCAlertWasClosed');
      localStorage.removeItem('client');
      localStorage.removeItem('openIntercom');
      localStorage.removeItem('last_route');
    }
  }

  deleteCookie(): void {
    if (!environment.production) { // for local test - do not remove
      this._cookieService.deleteAll('/', 'abobot.loc');
    } else {
      this._cookieService.deleteAll('/', 'mentortools.com');
    }
  }

}
