import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import { ServerSideRenderingService } from '@coreservices/server-side-rendering.service';
import { Observable } from 'rxjs';
import { UserService } from '../services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class SignupGuard implements CanActivate {
  constructor(private _userService: UserService,
              private _ssr: ServerSideRenderingService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(this._ssr.isBrowser) {
        return new Promise<boolean>(resolve => {
          if (state.url.indexOf('payment-after-trial') !== -1) {
            resolve(true)
            return;
          }  
          if (this._userService.token) {
            this._userService.deleteClient();
            window.location.reload();     
          }
          resolve(true)
        });
      }
  }
}
