import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from './user/user.service';

@UntilDestroy()
@Injectable()
export class StaticDataService {

  staticData: any;

  constructor(private _entityService: EntityService, private _userService: UserService,) {}

  getStaticInfo(): void {
    if(!localStorage.getItem('staticData')) {
      this._entityService
          .get('portal/constants/get')
          .pipe(untilDestroyed(this))
          .subscribe(
            resp => {
              if(resp) {
                this.staticData = resp;
                localStorage.setItem('staticData', JSON.stringify(this.staticData));
                this.setStartupData();
              }
            }
          )
    } else {
      this.staticData = JSON.parse(localStorage.getItem('staticData'));
      this.setStartupData();
    }
  }

  get StaticData(): any {
    return this.staticData;
  }

  setStartupData(): void {
    this._userService.setRoles(this.staticData.role);
    this._userService.setStatuses(this.staticData.user_status);
    if(this.staticData.portal_type) {
      this._userService.setPortalTypes(this.staticData.portal_type);
    }
  }
}