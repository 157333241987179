import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";

@Injectable()
export class ServerSideRenderingService {
  
  private _isBrowser: boolean = true;

  get isBrowser(): boolean {
    return this._isBrowser;
  }

  constructor(@Inject(PLATFORM_ID) public platformId: object) {
    this._isBrowser = isPlatformBrowser(this.platformId);
  }

}