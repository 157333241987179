import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { iAlertType } from '../interfaces/alert';

@Injectable()
export class GlobalEventManagerService {

  _showAlert: BehaviorSubject<iAlertType> = new BehaviorSubject<iAlertType>({});
  $showAlert: Observable<iAlertType> = this._showAlert.asObservable();

  _setAuthorize: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  $checkAuthority: Observable<boolean> = this._setAuthorize.asObservable();

  _updateUserProfile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  $updateUserProfile: Observable<boolean> = this._updateUserProfile.asObservable();

  _previewStart: BehaviorSubject<object> = new BehaviorSubject<object>({ mode: false, url: '/customer' });
  $previewStart: Observable<object> = this._previewStart.asObservable();

  _previewEnd: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $previewEnd: Observable<boolean> = this._previewEnd.asObservable();

  _mediaFolderOpenedId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  $mediaFolderOpenedId: Observable<number> = this._mediaFolderOpenedId.asObservable();

  _updatePage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $updatePage: Observable<boolean> = this._updatePage.asObservable();

  _videoUpload: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $videoUpload: Observable<boolean> = this._videoUpload.asObservable();


  constructor() {}

	showAlert(ifShow: iAlertType) {
    this._showAlert.next(ifShow);
  }

  setAuthorize(ifAuthorized: boolean) {
    this._setAuthorize.next(ifAuthorized);
  }

  updateUserProfile(isUpdate: boolean) {
    this._updateUserProfile.next(isUpdate);
  }

  previewStart(mode: object) {
    this._previewStart.next(mode);
  }

  previewEnd(mode: boolean) {
    this._previewEnd.next(mode);
  }

  setMediaFolderId(id: number): void {
    this._mediaFolderOpenedId.next(id);
  }

  updatePage(isUpdate: boolean) {
    this._updatePage.next(isUpdate);
  }

  videoUpload(isUpdate: boolean) {
    this._videoUpload.next(isUpdate);
  }

}
