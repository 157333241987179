import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { iEntityFilterParams } from '../_core/interfaces/entity-params';
import { EntityService } from '../_core/services/entity.service';
import { isNumber } from 'util';
import { UserService } from '../_core/services/user/user.service';
import { catchError, map } from 'rxjs/internal/operators';
import { HttpClient } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs/index';
import { iModule, iLesson, iCourse } from '../_core/interfaces/course';
import { environment } from '../../environments/environment';
import { ServerSideRenderingService } from '@coreservices/server-side-rendering.service';

@Injectable()
export class CustomerNewService {

  customerInfo: any;

  _startNewQuiz: BehaviorSubject<boolean> = new BehaviorSubject(false);
  $startNewQuiz: Observable<boolean> = this._startNewQuiz.asObservable();

  _quizComplete: BehaviorSubject<iCourse> = new BehaviorSubject(null);
  $quizComplete: Observable<iCourse> = this._quizComplete.asObservable();

  _changeLessonProgressStage: BehaviorSubject<iLesson> = new BehaviorSubject(null);
  $changeLessonProgressStage: Observable<iLesson> = this._changeLessonProgressStage.asObservable();

  public today: number = null;

  constructor(private _entityService: EntityService,
              private _userService: UserService,
              private _http: HttpClient,
              private _ssr: ServerSideRenderingService) {
    if(this._ssr.isBrowser) {
      this.setCurrentTimestamp();
    }
  }

  getCustomerInfo() {
    let requestParameters: iEntityFilterParams = {
      columns: ['**'],
      limit: 1,
      offset: 0,
      filter_by: [{attribute: 'id', operator: '=', value: this._userService.client.portal_member.id}],
      search_by: []
    };
    return this._entityService.getEntityInfo('portal_member', requestParameters).pipe(
      map((response: any) => {
        return response[0];
      })
    );
  }

  /*****************************************************  GETTERS  ****************************************************/

  getOrders(meanFilters: any = null): Observable<any> {
    let defaultFilterObj: iEntityFilterParams = {columns: ['*'], limit: 1000, filter_by: []};
    if (meanFilters !== undefined || meanFilters !== null) {
      if (meanFilters.portal_member_id) {
        if (isNumber(meanFilters.customer)) meanFilters.portal_member_id = [meanFilters.portal_member_id];
      }
      defaultFilterObj.filter_by.push({
        attribute: 'portal_member_id',
        operator: 'in',
        value: meanFilters.portal_member_id
      });
    }
    return this._entityService.getEntity('content_creator', defaultFilterObj);
  }

  /*****************************************************  GETTERS  ****************************************************/

  getSingleCustomerOrders(certainCourseId?: number): Observable<any> {
    let defaultFilterObj: iEntityFilterParams = {
      columns: ['*'],
      limit: 1000,
      order_by: [{
        column: 'occurred_at',
        ascending: true
      }]
    };
    if (certainCourseId) defaultFilterObj.filter_by = [{
      attribute: 'ownerships.course_id',
      operator: '=',
      value: certainCourseId
    }];
    return this._entityService.getEntity('order', defaultFilterObj);
  }

  setLessonProgress(lessonId, completeStageId = 3) {
    let entity = {
      lesson_progress_id: completeStageId,
      portal_member_id: this._userService.portal_member_id,
      lesson_id: lessonId
    };
    return of(this.getLessonProgress(lessonId).subscribe(
      item => {
        // if (item.length === 0) {
          // return this._entityService.addEntity('customer_lesson_progress', entity).subscribe(response => {
          // });
        // } else {
          // entity = item[0];
          // entity.lesson_progress_id = completeStageId;
          // return this._entityService.updateEntity('customer_lesson_progress', entity).subscribe(response => {
          // });
        // }
      }
    ));
  }

  updateLessonView(lessonId: number) {
    let entity;
    return of(this.getLessonProgress(lessonId).subscribe(
      item => {
        entity = item[0];
        if(entity.started_at === null) entity.started_at = new Date().getTime();
        entity.view_count = entity.view_count + 1;
        return this._entityService.updateEntity('customer_lesson_progress', entity).subscribe(response => {
        });
      }
    ));
  }

  setStartDate(lessonId: number, startDate?: number, startStageId?: number) {
    let entity;
    return of(this.getLessonProgress(lessonId).subscribe(
      item => {
        entity = item[0];
        if (startDate) entity.started_at = startDate;
        if (startStageId) entity.lesson_progress_id = startStageId;
        entity.view_count = entity.view_count + 1;
        return this._entityService.updateEntity('customer_lesson_progress', entity).subscribe(response => {
        });
      }
    ));
  }


  setCompleteDate(lessonId: number, completeDate?: number, completeStageId?: number) {
    let entity;
    return of(this.getLessonProgress(lessonId).subscribe(
      item => {
        entity = item[0];
        if (completeDate) entity.completed_at = completeDate;
        if (completeStageId) entity.lesson_progress_id = completeStageId;
        return this._entityService.updateEntity('customer_lesson_progress', entity).subscribe(response => {
        });
      }
    ));
  }

  calculateCourseProgress(course, progressData) {
    let courseLessonCount = 0;
    let courseLessonCountCompleted = 0;
    course.modules.sort(this.entityCompareByOrder);
    for (let i = 0; i < course.modules.length; i++) {
      let module: iModule = course.modules[i];
      let moduleLessonCount: number = module.lessons.length;
      let moduleLessonCountCompleted: number = 0;

      module.lessons.sort(this.entityCompareByOrder);
      for (let j = 0; j < module.lessons.length; j++) {
        let lesson: iLesson = module.lessons[j];
        lesson.progress_stage = progressData[course.modules[i].lessons[j].id];
        lesson.linkable = true;

        if (progressData[lesson.id] === 3) { // passed
          moduleLessonCountCompleted++;
        } else {
          if (!course.next_lesson_id) course.next_lesson_id = lesson.id;
        }

        course.last_lesson_id = lesson.id;
      }

      course.modules[i].lesson_count = moduleLessonCount;
      course.modules[i].lesson_count_completed = moduleLessonCountCompleted;
      course.modules[i].lesson_progress = Math.round((moduleLessonCountCompleted * 100) / moduleLessonCount);
      courseLessonCount += moduleLessonCount;
      courseLessonCountCompleted += moduleLessonCountCompleted;
    }

    course.lesson_count = courseLessonCount;
    course.lesson_count_completed = courseLessonCountCompleted;
    course.lesson_progress = Math.round((courseLessonCountCompleted * 100) / courseLessonCount);
    if (isNaN(course.lesson_progress)) course.lesson_progress = 0;

    return course;
  }

  fillCourseLessonCount(course: any) {
    let courseLessonCount = 0;
    for (let i = 0; i < course.modules.length; i++) {
      let module: iModule = course.modules[i];
      let moduleLessonCount: number = module.lessons.length;
      course.modules[i].lesson_count = moduleLessonCount;
      courseLessonCount += moduleLessonCount;
    }

    course.lesson_count = courseLessonCount;
    return course;
  }

  entityCompareByOrder(a, b): any {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    // if (!a.order && !b.order) return 0;
    return 0;
  }

  getAllLessonProgressData(portalMemberId: number = this._userService.portal_member_id) {
    let requestParameters: iEntityFilterParams = {
      columns: ['*'],
      limit: 10000,
      offset: 0,
      filter_by: [
        { attribute: 'portal_member_id', operator: '=', value: portalMemberId}
      ],
      search_by: []
    };

    return this._entityService.getEntityInfo('customer_lesson_progress', requestParameters);
  }

  getLessonProgress(lessonId) {
    let requestParameters: iEntityFilterParams = {
      columns: ['**'],
      limit: 1,
      offset: 0,
      filter_by: [
        {attribute: 'lesson_id', operator: '=', value: lessonId},
        {attribute: 'portal_member_id', operator: '=', value: this._userService.portal_member_id}
      ],
      search_by: []
    };

    return this._entityService.getEntityInfo('customer_lesson_progress', requestParameters);
  }

  getSettingsDesignOnLogin(): Observable<any> {
    let reqURL = environment.apiUrl + '/settings/design?token=no';

    return this._http.get(reqURL).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.message || 'Server error (getListByTypeID - create)')));
  }

  /* Get group list filtered by type id (contract || group) */
  getSettingsOnLogin(): Observable<any> {
    let reqURL = environment.apiUrl + '/settings/login?token=no';

    return this._http.get(reqURL).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.message || 'Server error (getListByTypeID - create)')));
  }

  getCurrentTimestamp(): Observable<any> {
    let reqURL = environment.apiUrl + '/current_timestamp/get';

    return this._http.get(reqURL).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.message || 'Server error (getListByTypeID - create)')));
  }

  setCurrentTimestamp(): void {
    this.getCurrentTimestamp().subscribe(timestamp => {
      this.today = timestamp.result;
    })
  }

  startNewQuiz(isStarted: boolean) {
    this._startNewQuiz.next(isStarted);
  }

  getTranslateToModulesAndLessonsCount(count: number, entity: string): string {
    if(count === 0) {
      return 'COURSES_LIST_PAGE.course_list_more_five_'+entity+'s_number_label';
    } else if (count === 1) {
      return 'COURSES_LIST_PAGE.course_list_one_'+entity+'_number_label';
    } else if(count > 1 && count < 5) {
      return 'COURSES_LIST_PAGE.course_list_less_five_'+entity+'s_number_label';
    } else {
      return 'COURSES_LIST_PAGE.course_list_more_five_'+entity+'s_number_label';
    }
  }

  getTranslateToQuizzesCount(count: number): string {
    if(count === 0) {
      return 'COURSES_LIST_PAGE.course_list_more_five_quizzes_number_label';
    } else if (count === 1) {
      return 'COURSES_LIST_PAGE.course_list_one_quiz_number_label';
    } else if(count > 1 && count < 5) {
      return 'COURSES_LIST_PAGE.course_list_less_five_quizzes_number_label';
    } else {
      return 'COURSES_LIST_PAGE.course_list_more_five_quizzes_number_label';
    }
  }

  getCountOfQuizAndLessons(type: number, lessons: any): number {
    let count: number = 0;
    lessons.forEach(lesson => {
      if (lesson.type_id === type) count++;
    })
    return count;
  }

  changeLessonProgressStage(lesson: iLesson) {
    this._changeLessonProgressStage.next(lesson);
  }

  quizComplete(course: iCourse) {
    this._quizComplete.next(course);
  }
}
