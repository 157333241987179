import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user/user.service';
import { EntityService } from '../services/entity.service';
import { ServerSideRenderingService } from '@coreservices/server-side-rendering.service';

@Injectable()
export class ContentCreatorGuard implements CanActivate {

  constructor(private _router: Router,
              private _userService: UserService,
              private _entityService: EntityService,
              private _ssr: ServerSideRenderingService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if(this._ssr.isBrowser) {
      return new Promise<boolean>(resolve => {
        this._entityService.prolongate().subscribe(
          success_response => {
            const role = this._userService.getRole();
            if(role === 'customer' || role === 'hidden_customer' || role === 'demo_hidden_customer') {
              this._router.navigate(['/customer']);
              resolve(false);
            }
            resolve(true);

          },
          error => {
            this._userService.deleteClient();
            this._router.navigate(['/login']);
            resolve(false);
          }
        );
      });
    }
  }
}
