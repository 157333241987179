import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeysPipe } from './array.pipe';
import { SortByPipe } from './sort.pipe';
import { SafePipe } from './safe.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { XAxisTickFormatPipe } from './x-axis-tick-format.pipe';
import { TruncatePipe } from './truncate.pipe';
import { DomainPipe, SubDomainPipe } from './domain.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    KeysPipe,
    SortByPipe,
    SafePipe,
    SafeUrlPipe,
    XAxisTickFormatPipe,
    TruncatePipe,
    DomainPipe,
    SubDomainPipe
  ],
  providers: [],
  exports: [
    KeysPipe,
    SortByPipe,
    SafePipe,
    SafeUrlPipe,
    XAxisTickFormatPipe,
    TruncatePipe,
    DomainPipe,
    SubDomainPipe
  ]
})
export class PipesModule { }
