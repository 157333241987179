// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  type: 'develop',
  production: true,
  apiUrl: 'https://' + window.location.hostname + '/entity_api',
  uploadApiUrl: 'https://dev.upload.mentortools.com/entity_api',
  exportApiUrl: 'https://' + window.location.hostname + '/export_api',
  importApiUrl: 'https://' + window.location.hostname + '/import_api',
  notificationWssUrl: 'dev.mentortools.com',
  usplashKey: 'Kw35vNwPguv4nN9rPwW7ivpTvotfnjr-bGH_yyXJJCI',
  // temporarily for cache
  fileApiUrl: 'https://dev.mentortools.com/entity_api',
  communityApi: {
    version: 'v1',
    url: 'https://' + window.location.hostname + '/community_api',
  },
  notificationApi: {
    version: 'v1',
    url: 'https://' + window.location.hostname + '/notifications_api',
  },
  integrationApi: {
    version: 'v1',
    url: 'https://' + window.location.hostname + '/integration_api',
  },
  openAIApi: {
    version: 'v1',
    url: 'https://' + window.location.hostname + '/openai_connector_api'
  },
  senderApi: {
    version: 'v1',
    url: 'https://' + window.location.hostname + '/email_server_api',
  },
  authApi: {
    version: 'v1',
    url: 'https://' + window.location.hostname + '/entity_api/auth',
  },
  landingApi: {
    version: 'v1',
    url: 'https://' + window.location.hostname + '/landing_page_api',
  },
  calendarApi: {
    version: 'v1',
    url: 'https://' + window.location.hostname + '/calendar_api',
  },
  availableLanguages: ['en', 'de', 'sk']
};
