import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user/user.service';
import { EntityService } from '../services/entity.service';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { AlertService } from '@coreservices/alert.service';
import { ServerSideRenderingService } from '@coreservices/server-side-rendering.service';

@Injectable()
export class AccessGuard implements CanActivate {

  constructor(private _router: Router,
              private _userService: UserService,
              private _entityService: EntityService,
              private _alertService: AlertService,
              private _location: Location,
              private _ssr: ServerSideRenderingService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(this._ssr.isBrowser) {
      return new Promise<boolean>(resolve => {
        if (state.url.indexOf('free-course') !== -1) {
          resolve(true);
          return;
        }

        this._entityService.prolongate().subscribe(
          (success_response) => {
            this._userService.setClient(success_response);
            this._userService.setTrialPortalTime(success_response.portal.trial_end_time);
            if (window.location.search.substr(1).length > 0) {
              let hashParam: any = window.location.search.substr(1).split('=');
              if (hashParam.length > 0 && hashParam[0] === 'hash') this._location.replaceState('/admin');
              if (hashParam.length > 0 && hashParam[0] === 'hilfe') {
                localStorage.setItem('openIntercom', 'true');
                this._location.replaceState('/admin');
              }
            }

            resolve(true);
          },
          error => {
            if(window.location.search.includes('lesson_public')) {
              setTimeout(() => {
                this._alertService.showError('unautorazed_lesson_access', 'lesson')
              }, 500)
            }
            this._userService.deleteClient();
            this._router.navigateByUrl('/login'+window.location.search);
            resolve(false);
          }
        );
      });
    }
  }


}
