import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CoreComponent } from './core.component';
import { UserService } from './services/user/user.service';
import { AuthenticationInterceptor } from './components/user/authentication/authentication.interceptor';
import { CoreRoutingModule } from './core-routing.module';
import { AccessGuard } from './guards/access.guard';
import { adapterFactory } from 'angular-calendar-mentor/date-adapters/date-fns';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreEntityService } from './services/_core.entity.service';
import { EntityService } from './services/entity.service';
import { AlertService } from './services/alert.service';
import { ContentCreatorGuard } from './guards/content-creator.guard';
import { FileManagerService } from './services/file-manager.service';
import { GlobalEventManagerService } from './services/global-event-manager.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { FacebookPixelService } from './services/facebook-pixel.service';
import { SettingsService } from './services/settings.service';
import { DeleteConfirmDialogComponent } from './components/dialog/delete-confirm/delete-confirm-dialog.component';

// need to prevent cache
const timestamp = (new Date()).getTime();

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json?ngsw-bypass=true&t='+timestamp);
}

import { FileStorageDialogComponent } from './components/dialog/file-storage-dialog/file-storage-dialog.component';
import { HttpRequestService } from './services/http-request.service';
import { CoreInit } from './core.init';
import * as Sentry from "@sentry/angular";
import { Router, RouteReuseStrategy } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { SignupGuard } from './guards/signup.guard';
import { ImageUploadModule } from "angular2-image-upload";
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LangPaginatorIntl } from './services/lang-paginator-intl';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CommunityService } from '@coreservices/community.service';
import { CommunitySettingsService } from '@coreservices/community-settings.service';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ServerSideRenderingService } from '@coreservices/server-side-rendering.service';
import { LoaderInterceptor } from '@coreinterceptors/loader.interceptor';
import { CachingInterceptor } from '@coreinterceptors/cache.interceptor';
import { RequestCache } from '@coreservices/cache.service';
import { CacheRouteReuseStrategy } from '@corestrategies/cache-route-reuse.strategy';
import { NewDesignSettingsService } from '@coreservices/new-design-settings.serivce';
import { CustomerNewService } from '../customer/customer.service';
import { NgxMaskModule } from 'ngx-mask'
import { NotificationsService } from '@coreservices/notifications.service';
import { VideoService } from '@coreservices/video.service';
import { DesignStartupService } from '@coreservices/design-startup.service';
import { LoginStartupService } from '@coreservices/login-startup.service';
import { StaticDataService } from '@coreservices/static-info.service';
import { TrialEndGuard } from '@coreguards/trial-end.guard';
import { CookieBannerModule } from '@corewidgets/cookie-banner/cookie-banner.module';
import { CalendarModule, DateAdapter } from 'angular-calendar-mentor';
import { TrialPeriodService } from '@coreservices/trial-period.service';
import { environment } from 'environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SupportGuard } from '@coreguards/support.guard';
import { CommunityLinkGuard } from '@coreguards/community-link.guard';

@NgModule({
  declarations: [
    CoreComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    CoreRoutingModule,
    CookieBannerModule,
    CookieBannerModule,
    DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ImageUploadModule.forRoot(),
		ToastrModule.forRoot({
			timeOut: 5000,
			positionClass: 'toast-top-right',
		}),
    NgMultiSelectDropDownModule.forRoot(),
    ScrollToModule.forRoot(),
    NgxMaskModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptor,
      multi: true,
    },
    CoreInit,
    {
      provide: APP_INITIALIZER,
      useFactory: (core: CoreInit) => () => core.initAccess(),
      deps: [CoreInit],
      multi: true
    },
    VideoService,
    CustomerNewService,
    CustomerNewService,
    AccessGuard,
    CommunityLinkGuard,
    ContentCreatorGuard,
    SignupGuard,
		EntityService,
		AlertService,
    UserService,
    CoreEntityService,
    CommunityService,
    NotificationsService,
    CommunitySettingsService,
    FileManagerService,
    GlobalEventManagerService,
    GoogleAnalyticsService,
    FacebookPixelService,
    SettingsService,
    HttpRequestService,
    NewDesignSettingsService,
    DesignStartupService,
    LoginStartupService,
    StaticDataService,
    RequestCache,
    TrialEndGuard,
    TrialPeriodService,
    SupportGuard,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new LangPaginatorIntl(translateService).getlangPaginatorIntl()
    },
    ServerSideRenderingService,
  ],
  bootstrap: [ CoreComponent ],
  entryComponents: [
    DeleteConfirmDialogComponent,
    FileStorageDialogComponent,
  ]
})
export class CoreModule {}
