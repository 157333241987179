import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from '../services/user/user.service';
import {EntityService} from '../services/entity.service';

@Injectable({
  providedIn: 'root'
})
export class UserOnboardingGuard implements CanActivate {

  constructor(
    private _userService: UserService, 
    private _router: Router,
    private _entityService: EntityService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  const onboardingStep: string = this._userService.client?.portal_member?.user_onboarding_step;
  // Trial period ended checker
  if (this._userService.getPortalType() === 'trial_ended') {
    this._router.navigateByUrl('/trial-period-end');
    return false;
  }
  /******************/
  if (
    this._userService.getRole() === 'content_creator' &&
    !onboardingStep?.includes('finish')
  ) {
      let route = '/user-onboarding';
      if (onboardingStep) {
        route += onboardingStep;
      }
      const registered_at = this._userService.client.portal_member.registered_at;
      const now = Date.now();
      if (now - registered_at < 86400000) {
        this._router.navigateByUrl(route);
        return false;
      } else {
        this._entityService.update('user_profile', { id: 1, user_onboarding_step: 'finish'}).subscribe();
      }
    }
    return true;
  }
}
