import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AlertService {

	alertMessages: { [key: string]: any };

	constructor(private _toastr: ToastrService,
				private _translate: TranslateService) {	
		this._translate.onLangChange.subscribe(() => {
			this._translate.get('ALERTS').subscribe(alertsObject => {
				this.alertMessages = alertsObject;
			});
		})
	}

	showSuccess(event: string, entityType: string, data?: object) {
		let options;
		if (data) options = data;
		this._toastr.success(this.alertMessages.messages.success[event], this.alertMessages.entitys[entityType], options);
	}
	
	showError(event: string, entityType: string) {
		this._toastr.error(this.alertMessages.messages.error[event], this.alertMessages.entitys[entityType]);
	}

	showCustomError(message: string, entityType: string) {
		this._toastr.error(message, this.alertMessages.entitys[entityType]);
	}

}