export interface iDesignTemplate {
  id?: number;
  code?: string;
  type_id?: number;
  order?: number;
}

export interface iDesignTemplateType {
  id?: number;
  code?: string;
}

export interface iPortalToContentDesignTemplateBinding {
  id?: number;
  portal_id?: number;
  content_design_template_id?: number;
  is_active?: boolean;
  custom_content_design?: object;
  order?: number;
}

export interface iTheme {
  name: string;
  properties: any;
}

export const getDefaultThemeStructure = (): iTheme =>  { 
  return {  
    name: 'default-theme',
    properties: {
      /*** Global ***/ 
      '--headline-font-family': '\'PT Sans\', sans-serif',
      '--subheadline-font-family': '\'PT Sans\', sans-serif',
      '--paragraph-font-family': '\'PT Sans\', sans-serif',
      '--shadow-color': 'rgba(168,175, 196, 0.50)',
      '--link-font-color': '#387efc',
      /*** Local ***/ 
      // main bg
      '--main-bg-color': '#F0F3F4',
      /* General text */
      // headlines
      '--headline-font-color': '#4A4A4A',
      '--headline-font-style': 'normal',
      '--headline-font-decoration': 'none',
      '--headline-font-weight': 'bold',
      // subheadlines
      '--subheadline-font-color': '#4A4A4A',
      '--subheadline-font-style': 'normal',
      '--subheadline-font-decoration': 'none',
      '--subheadline-font-weight': 'bold',
      // paragraphs
      '--paragraph-font-color': '#4A4A4A',
      '--paragraph-font-style': 'normal',
      '--paragraph-font-decoration': 'none',
      '--paragraph-font-weight': 'normal',
      /* top-banner */
      // top-banner-global
      '--top-banner-container-min-height': '360px',
      // top-banner-headline
      '--top-banner-headline-font-color': '#4A4A4A',
      '--top-banner-headline-font-style': 'normal',
      '--top-banner-headline-font-decoration': 'none',
      '--top-banner-headline-font-weight': 'bold',
      // top-banner-subheadline
      '--top-banner-subheadline-font-color': '#4A4A4A',
      '--top-banner-subheadline-font-style': 'normal',
      '--top-banner-subheadline-font-decoration': 'none',
      '--top-banner-subheadline-font-weight': 'bold',  
      // top-banner-paragraph
      '--top-banner-paragraph-font-color': '#4A4A4A',
      '--top-banner-paragraph-font-style': 'normal',
      '--top-banner-paragraph-font-decoration': 'none',
      '--top-banner-paragraph-font-weight': 'normal',
      // top-banner-img
      '--top-banner-img-url': 'url(./assets/images/custom-design/header-banner.png)',
      '--top-banner-img-id': '',
      '--top-banner-bg-color': 'rgba(255, 255, 255, 0)',
      // global-nav 
      '--nav-bg-color': '#FFFFFF',
      '--nav-font-color': '#4A4A4A',
      '--nav-font-style': 'normal',
      '--nav-font-decoration': 'none',
      '--nav-font-weight': 'bold',
      // cards 
      '--card-bg-color': '#FFFFFF',
      '--card-font-color': '#4A4A4A',
      '--card-font-style': 'normal',
      '--card-font-decoration': 'none',
      '--card-font-weight': 'normal',
      // additional
      '--additional-color': '#1863EA',
      '--additional-bg-color': '#1863EA',
      '--snippet-border': '',
      /* buttons */
      '--btn-font-style': 'normal',
      '--btn-font-decoration': 'none',
      '--btn-font-weight': 'bold',
      // button-primary
      '--btn-primary-main-color': '#1863EA',
      '--btn-primary-additional-color': '#ffffff',
      // buttom-secondary
      '--btn-secondary-main-color': '#4A4A4A',
      '--btn-secondary-additional-color': '#ffffff',
      // buttom-success
      '--btn-success-main-color': '#14af1d',
      '--btn-success-additional-color': '#ffffff',
      // video-border
      '--video-border': '#1863EA',
      // badge
      '--badge-font-color': '#4A4A4A',
      '--badge-bg-color': '#FFFFFF',
      /* Footer */
      // footer-banner
      '--footer-display': true,
      '--footer-display-image': true,
      '--footer-banner-img-url': 'url(./assets/images/custom-design/footer-banner.png)',
      '--footer-banner-img-id': '',
      '--footer-banner-additional-img-url': './assets/images/custom-design/additional-footer-img.png',
      '--footer-banner-additional-img-id': '',
      '--footer-banner-bg-color': 'rgba(255, 255, 255, 0)',
      // footer-headline
      '--footer-headline-font-color': '#4A4A4A',
      '--footer-headline-font-style': 'normal',
      '--footer-headline-font-decoration': 'none',
      '--footer-headline-font-weight': 'bold',
      // footer-paragraph
      '--footer-paragraph-font-color': '#4A4A4A',
      '--footer-paragraph-font-style': 'normal',
      '--footer-paragraph-font-decoration': 'none',
      '--footer-paragraph-font-weight': 'normal',
      // footer-content
      '--footer-headline-content':"",
      '--footer-paragraph-content': "",
      
      //Preset images
      '--img-logo-login':     '',
      '--img-logo-dashboard': '',
      '--img-favicon':        '',
      '--img-login-desktop':  '',
      '--img-login-mobile':   '',
      '--img-user-avatar':    '',
      '--img-shoutout-box':   '',
      '--img-cml-wrp':        ''
    } 
  }
};

export const custom: iTheme = {
  name: 'custom',
  properties: {}
};

export const main: iTheme = {
  name: 'main',
  properties: {
  /*** Global ***/ 
  '--headline-font-family': '\'PT Sans\', sans-serif',
  '--subheadline-font-family': '\'PT Sans\', sans-serif',
  '--paragraph-font-family': '\'PT Sans\', sans-serif',

  '--shadow-color': 'rgba(168, 175, 196, 0.50)',
  '--link-font-color': '#387efc',

  /*** Local ***/ 
  // main bg
  '--main-bg-color': '#F0F3F4',

  /* General text */
  // headlines
  '--headline-font-color': '#4A4A4A',
  '--headline-font-style': 'normal',
  '--headline-font-decoration': 'none',
  '--headline-font-weight': '700',
  // subheadlines
  '--subheadline-font-color': '#4A4A4A',
  '--subheadline-font-style': 'normal',
  '--subheadline-font-decoration': 'none',
  '--subheadline-font-weight': '700',
  // paragraphs
  '--paragraph-font-color': '#4A4A4A',
  '--paragraph-font-style': 'normal',
  '--paragraph-font-decoration': 'none',
  '--paragraph-font-weight': 'normal',

  /* top-banner */
  // top-banner-global
  '--top-banner-container-min-height': 'auto',
  // top-banner-headline
  '--top-banner-headline-font-color': '#4A4A4A',
  '--top-banner-headline-font-style': 'normal',
  '--top-banner-headline-font-decoration': 'none',
  '--top-banner-headline-font-weight': '700',
  // top-banner-subheadline
  '--top-banner-subheadline-font-color': '#4A4A4A',
  '--top-banner-subheadline-font-style': 'normal',
  '--top-banner-subheadline-font-decoration': 'none',
  '--top-banner-subheadline-font-weight': '700',  
  // top-banner-paragraph
  '--top-banner-paragraph-font-color': '#4A4A4A',
  '--top-banner-paragraph-font-style': 'normal',
  '--top-banner-paragraph-font-decoration': 'none',
  '--top-banner-paragraph-font-weight': 'normal',
  // top-banner-img
  '--top-banner-img-url': '',
  '--top-banner-bg-color': '#F0F3F4',

  // global-nav 
  '--nav-bg-color': '#FFFFFF',
  '--nav-font-color': '#4A4A4A',
  '--nav-font-style': 'normal',
  '--nav-font-decoration': 'none',
  '--nav-font-weight': '700',

  // cards 
  '--card-bg-color': '#FFFFFF',
  '--card-font-color': '#4A4A4A',
  '--card-font-style': 'normal',
  '--card-font-decoration': 'none',
  '--card-font-weight': 'normal',

  // additional
  '--additional-color': '#1863EA',
  '--additional-bg-color': '#1863EA',
  '--snippet-border': '1px solid #dee2e6',

  /* buttons */
  '--btn-font-style': 'normal',
  '--btn-font-decoration': 'none',
  '--btn-font-weight': '500',
  // button-primary
  '--btn-primary-main-color': '#1863EA',
  '--btn-primary-additional-color': '#ffffff',
  // buttom-secondary
  '--btn-secondary-main-color': '#4A4A4A',
  '--btn-secondary-additional-color': '#ffffff',
  // buttom-success
  '--btn-success-main-color': '#14af1d',
  '--btn-success-additional-color': '#ffffff',

  // video-border
  '--video-border': '#1863EA',

  // badge
  '--badge-font-color': '#4A4A4A',
  '--badge-bg-color': '#FFFFFF',

  /* Footer */
  // footer-banner
  '--footer-banner-img-url': '',
  '--footer-banner-additional-img-url': '',
  '--footer-banner-bg-color': '#4A4A4A',
  // footer-headline
  '--footer-headline-font-color': '#4A4A4A',
  '--footer-headline-font-style': 'normal',
  '--footer-headline-font-decoration': 'none',
  '--footer-headline-font-weight': '700',
  // footer-paragraph
  '--footer-paragraph-font-color': '#4A4A4A',
  '--footer-paragraph-font-style': 'normal',
  '--footer-paragraph-font-decoration': 'none',
  '--footer-paragraph-font-weight': 'normal'
  }
};

export const dark_mint: iTheme = {
  name: 'dark-mint',
  properties: {

    /*** Global ***/ 
    '--headline-font-family': '\'Heebo\', sans-serif',
    '--subheadline-font-family': '\'Heebo\', sans-serif',
    '--paragraph-font-family': '\'PT Sans\', sans-serif',
    
    '--shadow-color': 'rgba(0, 0, 0, 0.25)',
    '--link-font-color': '#55e2d8',

    /*** Local ***/ 
    // main bg
    '--main-bg-color': '#212529',

    /* General text */
    // headlines
    '--headline-font-color': '#55e2d8',
    '--headline-font-style': 'normal',
    '--headline-font-decoration': 'none',
    '--headline-font-weight': '700',
    // subheadlines
    '--subheadline-font-color': '#FFFFFF',
    '--subheadline-font-style': 'normal',
    '--subheadline-font-decoration': 'none',
    '--subheadline-font-weight': '700',
    // paragraphs
    '--paragraph-font-color': '#FFFFFF',
    '--paragraph-font-style': 'normal',
    '--paragraph-font-decoration': 'none',
    '--paragraph-font-weight': 'normal',

    /* Top-banner */
    // top-banner-global
    '--top-banner-container-min-height': 'auto',
    // top-banner-headline
    '--top-banner-headline-font-color': '#FFFFFF',
    '--top-banner-headline-font-style': 'normal',
    '--top-banner-headline-font-decoration': 'none',
    '--top-banner-headline-font-weight': '700',
    // top-banner-subheadline
    '--top-banner-subheadline-font-color': '#55e2d8',
    '--top-banner-subheadline-font-style': 'normal',
    '--top-banner-subheadline-font-decoration': 'none',
    '--top-banner-subheadline-font-weight': '700',
    // top-banner-paragraph
    '--top-banner-paragraph-font-color': '#FFFFFF',
    '--top-banner-paragraph-font-style': 'normal',
    '--top-banner-paragraph-font-decoration': 'none',
    '--top-banner-paragraph-font-weight': 'normal',
    // top-banner-img
    '--top-banner-img-url': '',
    '--top-banner-bg-color': '',
    // global-nav 
    '--nav-bg-color': '#3d4146',
    '--nav-font-color': '#ffffff',
    '--nav-font-style': 'normal',
    '--nav-font-decoration': 'none',
    '--nav-font-weight': 'normal',
    // cards 
    '--card-bg-color': '#3d4146',
    '--card-font-color': '#dedede',
    '--card-font-style': 'normal',
    '--card-font-decoration': 'none',
    '--card-font-weight': 'normal',

    /* Additional */
    '--additional-color': '#55e2d8',
    '--additional-bg-color': '#45A29E',
    '--snippet-border': '1px solid #535558',

    /* Buttons */
    '--btn-font-style': 'normal',
    '--btn-font-decoration': 'none',
    '--btn-font-weight': 'normal',
    // button-primary
    '--btn-primary-main-color': '#43bcb8',
    '--btn-primary-additional-color': '#ffffff',
    // buttom-secondary
    '--btn-secondary-main-color': '#b8b8b8',
    '--btn-secondary-additional-color': '#ffffff',
    // buttom-success
    '--btn-success-main-color': '#14af1d',
    '--btn-success-additional-color': '#ffffff',

    // video-border
    '--video-border': '#45A29E',

    // badge
    '--badge-font-color': '#ffffff',
    '--badge-bg-color': '#45A29E',

    /* Footer */
    // footer-banner
    '--footer-banner-img-url': '',
    '--footer-banner-additional-img-url': '',
    '--footer-banner-bg-color': '',
    // footer-headline
    '--footer-headline-font-color': '#55e2d8',
    '--footer-headline-font-style': 'normal',
    '--footer-headline-font-decoration': 'none',
    '--footer-headline-font-weight': '700',
    // footer-paragraph
    '--footer-paragraph-font-color': '#ffffff',
    '--footer-paragraph-font-style': 'normal',
    '--footer-paragraph-font-decoration': 'none',
    '--footer-paragraph-font-weight': 'normal'
  }
};

export const light_red: iTheme = {
  name: 'light-red',
  properties: {

    /*** Global ***/ 
    '--headline-font-family': '\'PT Sans\', sans-serif',
    '--subheadline-font-family': '\'PT Sans\', sans-serif',
    '--paragraph-font-family': '\'PT Sans\', sans-serif',
  
    '--shadow-color': '#756060c4',
    '--link-font-color': '#e74c3c',
    /*** Local ***/ 
    // main bg
    '--main-bg-color': '#E3E3E3',

    /* General text */
    // headlines
    '--headline-font-color': '#e74c3c',
    '--headline-font-style': 'normal',
    '--headline-font-decoration': 'none',
    '--headline-font-weight': '700',
    // subheadlines
    '--subheadline-font-color': '#4A4A4A',
    '--subheadline-font-style': 'normal',
    '--subheadline-font-decoration': 'none',
    '--subheadline-font-weight': '700',
    // paragraphs
    '--paragraph-font-color': '#4A4A4A',
    '--paragraph-font-style': 'normal',
    '--paragraph-font-decoration': 'none',
    '--paragraph-font-weight': 'normal',

    /* top-banner */
    // top-banner-global
    '--top-banner-container-min-height': 'auto',
    // top-banner-headline
    '--top-banner-headline-font-color': '#4A4A4A',
    '--top-banner-headline-font-style': 'normal',
    '--top-banner-headline-font-decoration': 'none',
    '--top-banner-headline-font-weight': '700',
    // top-banner-subheadline
    '--top-banner-subheadline-font-color': '#4A4A4A',
    '--top-banner-subheadline-font-style': 'normal',
    '--top-banner-subheadline-font-decoration': 'none',
    '--top-banner-subheadline-font-weight': '700',
    // top-banner-paragraph
    '--top-banner-paragraph-font-color': '#4A4A4A',
    '--top-banner-paragraph-font-style': 'normal',
    '--top-banner-paragraph-font-decoration': 'none',
    '--top-banner-paragraph-font-weight': 'normal',
    // top-banner-img
    '--top-banner-img-url': '',
    '--top-banner-bg-color': '',

    // global-nav 
    '--nav-bg-color': '#F6F6F8',
    '--nav-font-color': '#4A4A4A',
    '--nav-font-style': 'normal',
    '--nav-font-decoration': 'none',
    '--nav-font-weight': 'normal',

    // cards 
    '--card-bg-color': '#F6F6F8',
    '--card-font-color': '#4A4A4A',
    '--card-font-style': 'normal',
    '--card-font-decoration': 'none',
    '--card-font-weight': 'normal',

    // additional
    '--additional-color': '#6e7379',
    '--additional-bg-color': '#e74c3c',
    '--snippet-border': '',

    /* buttons */
    '--btn-font-style': 'normal',
    '--btn-font-decoration': 'none',
    '--btn-font-weight': 'normal',
    // button-primary
    '--btn-primary-main-color': '#e74c3c',
    '--btn-primary-additional-color': '#ffffff',
    // buttom-secondary
    '--btn-secondary-main-color': '#5a5a5a',
    '--btn-secondary-additional-color': '#ffffff',
    // buttom-success
    '--btn-success-main-color': '#14af1d',
    '--btn-success-additional-color': '#ffffff',

    // video-border
    '--video-border': '#C60021',

    // badge
    '--badge-font-color': '#ffffff',
    '--badge-bg-color': '#e74c3c',

    /* Footer */
    // footer-banner
    '--footer-banner-img-url': '',
    '--footer-banner-additional-img-url': '',
    '--footer-banner-bg-color': '',
    // footer-headline
    '--footer-headline-font-color': '#e74c3c',
    '--footer-headline-font-style': 'normal',
    '--footer-headline-font-decoration': 'none',
    '--footer-headline-font-weight': '700',
    // footer-paragraph
    '--footer-paragraph-font-color': '#4A4A4A',
    '--footer-paragraph-font-style': 'normal',
    '--footer-paragraph-font-decoration': 'none',
    '--footer-paragraph-font-weight': 'normal'
  }
};

export const progressive_light: iTheme = {
  name: 'progressive-light',
  properties: {
    /*** Global ***/ 
    '--headline-font-family': '\'PT Sans\', sans-serif',
    '--subheadline-font-family': '\'PT Sans\', sans-serif',
    '--paragraph-font-family': '\'PT Serif\', sans-serif',

    '--shadow-color': '#727479c4',
    '--link-font-color': '#2196f3',
    /*** Local ***/ 
    // main bg
    '--main-bg-color': '#ffffff',

    /* General text */
    // headlines
    '--headline-font-color': '#173052',
    '--headline-font-style': 'normal',
    '--headline-font-decoration': 'none',
    '--headline-font-weight': '700',
    // subheadlines
    '--subheadline-font-color': '#4A4A4A',
    '--subheadline-font-style': 'normal',
    '--subheadline-font-decoration': 'none',
    '--subheadline-font-weight': '700',
    // paragraphs
    '--paragraph-font-color': '#091a31',
    '--paragraph-font-style': 'normal',
    '--paragraph-font-decoration': 'none',
    '--paragraph-font-weight': 'normal',

    /* top-banner */
    // top-banner-global
    '--top-banner-container-min-height': 'auto',
    // top-banner-headline
    '--top-banner-headline-font-color': '#4A4A4A',
    '--top-banner-headline-font-style': 'normal',
    '--top-banner-headline-font-decoration': 'none',
    '--top-banner-headline-font-weight': '700',
    // top-banner-subheadline
    '--top-banner-subheadline-font-color': '#173052',
    '--top-banner-subheadline-font-style': 'normal',
    '--top-banner-subheadline-font-decoration': 'none',
    '--top-banner-subheadline-font-weight': '700',
    // top-banner-paragraph
    '--top-banner-paragraph-font-color': '#091a31',
    '--top-banner-paragraph-font-style': 'normal',
    '--top-banner-paragraph-font-decoration': 'none',
    '--top-banner-paragraph-font-weight': 'normal',
    // top-banner-img
    '--top-banner-img-url': '',
    '--top-banner-bg-color': '',

    // global-nav 
    '--nav-bg-color': '#f9f9f9',
    '--nav-font-color': '#4A4A4A',
    '--nav-font-style': 'normal',
    '--nav-font-decoration': 'none',
    '--nav-font-weight': 'normal',

    // cards 
    '--card-bg-color': '#f9f9f9',
    '--card-font-color': '#4A4A4A',
    '--card-font-style': 'normal',
    '--card-font-decoration': 'none',
    '--card-font-weight': 'normal',

    // additional
    '--additional-color': '#2196f3',
    '--additional-bg-color': '#2196f3',
    '--snippet-border': '',

    /* buttons */
    '--btn-font-style': 'normal',
    '--btn-font-decoration': 'none',
    '--btn-font-weight': 'normal',
    // button-primary
    '--btn-primary-main-color': '#2196f3',
    '--btn-primary-additional-color': '#ffffff',
    // buttom-secondary
    '--btn-secondary-main-color': '#5a5a5a',
    '--btn-secondary-additional-color': '#ffffff',
    // buttom-success
    '--btn-success-main-color': '#14af1d',
    '--btn-success-additional-color': '#ffffff',

    // video-border
    '--video-border': '#2196f3',

    // badge
    '--badge-font-color': '#ffffff',
    '--badge-bg-color': '#2196f3',

    /* Footer */
    // footer-banner
    '--footer-banner-img-url': '',
    '--footer-banner-additional-img-url': '',
    '--footer-banner-bg-color': '',
    // footer-headline
    '--footer-headline-font-color': '#173052',
    '--footer-headline-font-style': 'normal',
    '--footer-headline-font-decoration': 'none',
    '--footer-headline-font-weight': '700',
    // footer-paragraph
    '--footer-paragraph-font-color': '#091a31',
    '--footer-paragraph-font-style': 'normal',
    '--footer-paragraph-font-decoration': 'none',
    '--footer-paragraph-font-weight': 'normal'
  }
};

export const light: iTheme = {
  name: 'light',
  properties: {
  /*** Global ***/ 
  '--headline-font-family': '\'PT Sans\', sans-serif',
  '--subheadline-font-family': '\'PT Sans\', sans-serif',
  '--paragraph-font-family': '\'PT Sans\', sans-serif',

  '--shadow-color': 'rgba(2 34 9 0.24)',
  '--link-font-color': '#1863EA',
  /*** Local ***/ 
  // main bg
  '--main-bg-color': '#ffffff',

  /* General text */
  // headlines
  '--headline-font-color': '#4A4A4A',
  '--headline-font-style': 'normal',
  '--headline-font-decoration': 'none',
  '--headline-font-weight': '700',
  // subheadlines
  '--subheadline-font-color': '#4A4A4A',
  '--subheadline-font-style': 'normal',
  '--subheadline-font-decoration': 'none',
  '--subheadline-font-weight': '700',
  // paragraphs
  '--paragraph-font-color': '#4A4A4A',
  '--paragraph-font-style': 'normal',
  '--paragraph-font-decoration': 'none',
  '--paragraph-font-weight': 'normal',

  /* top-banner */
  // top-banner-global
  '--top-banner-container-min-height': 'auto',
  // top-banner-headline
  '--top-banner-headline-font-color': '#4A4A4A',
  '--top-banner-headline-font-style': 'normal',
  '--top-banner-headline-font-decoration': 'none',
  '--top-banner-headline-font-weight': '700',
  // top-banner-subheadline
  '--top-banner-subheadline-font-color': '#4A4A4A',
  '--top-banner-subheadline-font-style': 'normal',
  '--top-banner-subheadline-font-decoration': 'none',
  '--top-banner-subheadline-font-weight': '700',  
  // top-banner-paragraph
  '--top-banner-paragraph-font-color': '#4A4A4A',
  '--top-banner-paragraph-font-style': 'normal',
  '--top-banner-paragraph-font-decoration': 'none',
  '--top-banner-paragraph-font-weight': 'normal',
  // top-banner-img
  '--top-banner-img-url': '',
  '--top-banner-bg-color': '#ffffff',

  // global-nav 
  '--nav-bg-color': '#F0F3F4',
  '--nav-font-color': '#4A4A4A',
  '--nav-font-style': 'normal',
  '--nav-font-decoration': 'none',
  '--nav-font-weight': '700',

  // cards 
  '--card-bg-color': '#F0F3F4',
  '--card-font-color': '#4A4A4A',
  '--card-font-style': 'normal',
  '--card-font-decoration': 'none',
  '--card-font-weight': 'normal',

  // additional
  '--additional-color': '#1863EA',
  '--additional-bg-color': '#28ac29',
  '--snippet-border': '',

  /* buttons */
  '--btn-font-style': 'normal',
  '--btn-font-decoration': 'none',
  '--btn-font-weight': '500',
  // button-primary
  '--btn-primary-main-color': '#28ac29',
  '--btn-primary-additional-color': '#ffffff',
  // buttom-secondary
  '--btn-secondary-main-color': '#5a5a5a',
  '--btn-secondary-additional-color': '#ffffff',
  // buttom-success
  '--btn-success-main-color': '#14af1d',
  '--btn-success-additional-color': '#ffffff',

  // video-border
  '--video-border': '#28ac29',

  // badge
  '--badge-font-color': '#4A4A4A',
  '--badge-bg-color': '#F0F3F4',

  /* Footer */
  // footer-banner
  '--footer-banner-img-url': '',
  '--footer-banner-additional-img-url': '',
  '--footer-banner-bg-color': '#4A4A4A',
  // footer-headline
  '--footer-headline-font-color': '#4A4A4A',
  '--footer-headline-font-style': 'normal',
  '--footer-headline-font-decoration': 'none',
  '--footer-headline-font-weight': '700',
  // footer-paragraph
  '--footer-paragraph-font-color': '#4A4A4A',
  '--footer-paragraph-font-style': 'normal',
  '--footer-paragraph-font-decoration': 'none',
  '--footer-paragraph-font-weight': 'normal'
  }
};

export const vip_jakob: iTheme = {
  name: 'vip-jakob',
  properties: {
    /*** Global ***/ 
    '--headline-font-family': '\'PT Sans\', sans-serif',
    '--subheadline-font-family': '\'PT Sans\', sans-serif',
    '--paragraph-font-family': '\'PT Sans\', sans-serif',
    '--shadow-color': 'rgba(84, 98, 140, 0.5)',
    '--link-font-color': '#8a6700',

    /*** Local ***/ 
    // main bg
    '--main-bg-color': '#F5F7F9',

    /* General text */
    // headlines
    '--headline-font-color': '#ecc00b',
    '--headline-font-style': 'normal',
    '--headline-font-decoration': 'none',
    '--headline-font-weight': '700',
    // subheadlines
    '--subheadline-font-color': '#ecc00b',
    '--subheadline-font-style': 'normal',
    '--subheadline-font-decoration': 'none',
    '--subheadline-font-weight': '700',
    // paragraphs
    '--paragraph-font-color': '#05368E',
    '--paragraph-font-style': 'normal',
    '--paragraph-font-decoration': 'none',
    '--paragraph-font-weight': 'normal',

    /* top-banner */
    // top-banner-global
    // '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
    // top-banner-headline
    '--top-banner-headline-font-color': '#05368E',
    '--top-banner-headline-font-style': 'normal',
    '--top-banner-headline-font-decoration': 'none',
    '--top-banner-headline-font-weight': '700',
    // top-banner-subheadline
    '--top-banner-subheadline-font-color': '#05368E',
    '--top-banner-subheadline-font-style': 'normal',
    '--top-banner-subheadline-font-decoration': 'none',
    '--top-banner-subheadline-font-weight': '700',
    // top-banner-paragraph
    '--top-banner-paragraph-font-color': '#05368E',
    '--top-banner-paragraph-font-style': 'normal',
    '--top-banner-paragraph-font-decoration': 'none',
    '--top-banner-paragraph-font-weight': 'normal',
    // top-banner-img
    '--top-banner-img-url': 'url(./assets/images/themes/vip-jakob-header.jpg)',
    '--top-banner-bg-color': '#b3b3b3',

    // global-nav 
    '--nav-bg-color': '#05368E',
    '--nav-font-color': '#FFFFFF',
    '--nav-font-style': 'normal',
    '--nav-font-decoration': 'none',
    '--nav-font-weight': '700',

    // cards 
    '--card-bg-color': '#05368E',
    '--card-font-color': '#FFFFFF',
    '--card-font-style': 'normal',
    '--card-font-decoration': 'none',
    '--card-font-weight': 'normal',

    // additional
    '--additional-color': '#FFCC00',
    '--additional-bg-color': '#FFCC00',
    '--snippet-border': '1px solid #082b6f',
    
    /* buttons */
    '--btn-font-style': 'normal',
    '--btn-font-decoration': 'none',
    '--btn-font-weight': '700',
    // button-primary
    '--btn-primary-main-color': '#FFCC00',
    '--btn-primary-additional-color': '#007bff',
    // buttom-secondary
    '--btn-secondary-main-color': '#afafaf',
    '--btn-secondary-additional-color': '#ffffff',
    // buttom-success
    '--btn-success-main-color': '#14af1d',
    '--btn-success-additional-color': '#ffffff',

    // video-border
    '--video-border': '#FFCC00',

    // badge
    '--badge-font-color': '#ffffff',
    '--badge-bg-color': '#05368E',

    /* Footer */
    // footer-banner
    '--footer-banner-img-url': 'url(./assets/images/themes/vip_yellow_blue_footer.jpg)',
    '--footer-banner-additional-img-url': './assets/images/themes/vip-jakob-prefooter-img.png',
    '--footer-banner-bg-color': '#969696',
    // footer-headline
    '--footer-headline-font-color': '#05368e',
    '--footer-headline-font-style': 'normal',
    '--footer-headline-font-decoration': 'none',
    '--footer-headline-font-weight': '700',
    // footer-paragraph
    '--footer-paragraph-font-color': '#ffffff',
    '--footer-paragraph-font-style': 'normal',
    '--footer-paragraph-font-decoration': 'none',
    '--footer-paragraph-font-weight': 'normal'
  }
};

export const vip_david: iTheme = {
  name: 'vip-david',
  properties: {
    /*** Global ***/ 
    '--headline-font-family': '\'Bebas Neue\', display',
    '--subheadline-font-family': '\'Bebas Neue\', display',
    '--paragraph-font-family': '\'PT Sans\', sans-serif',
    '--shadow-color': 'rgba(29, 23, 13, 0.55)',
    '--link-font-color': '#a58e66',

    /*** Local ***/ 
    // main bg
    '--main-bg-color': '#F5F7F9',

    /* General text */
    // headlines
    '--headline-font-color': '#d8af64',
    '--headline-font-style': 'normal',
    '--headline-font-decoration': 'none',
    '--headline-font-weight': '500',
    // subheadlines
    '--subheadline-font-color': '#d8af64',
    '--subheadline-font-style': 'normal',
    '--subheadline-font-decoration': 'none',
    '--subheadline-font-weight': '500',
    // paragraphs
    '--paragraph-font-color': '#000000',
    '--paragraph-font-style': 'normal',
    '--paragraph-font-decoration': 'none',
    '--paragraph-font-weight': 'normal',

    /* Top-banner */
    // top-banner-global
    // '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
    // top-banner-headline
    '--top-banner-headline-font-color': '#FFFFFF',
    '--top-banner-headline-font-style': 'normal',
    '--top-banner-headline-font-decoration': 'none',
    '--top-banner-headline-font-weight': '500',
    // top-banner-subheadline
    '--top-banner-subheadline-font-color': '#FFFFFF',
    '--top-banner-subheadline-font-style': 'normal',
    '--top-banner-subheadline-font-decoration': 'none',
    '--top-banner-subheadline-font-weight': '500',
    // top-banner-paragraph
    '--top-banner-paragraph-font-color': '#FFFFFF',
    '--top-banner-paragraph-font-style': 'normal',
    '--top-banner-paragraph-font-decoration': 'none',
    '--top-banner-paragraph-font-weight': 'normal',
    // top-banner-img
    '--top-banner-img-url': 'url(./assets/images/themes/vip-david-header.jpg)',
    '--top-banner-bg-color': '#00000000',

    // global-nav 
    '--nav-bg-color': '#212529',
    '--nav-font-color': '#ffffff',
    '--nav-font-style': 'normal',
    '--nav-font-decoration': 'none',
    '--nav-font-weight': 'normal',

    // cards 
    '--card-bg-color': '#212529',
    '--card-font-color': '#d8af64',
    '--card-font-style': 'normal',
    '--card-font-decoration': 'none',
    '--card-font-weight': 'normal',

    // additional
    '--additional-color': '#ffffff',
    '--additional-bg-color': '#d8af64',
    '--snippet-border': '1px solid #d8af641f',

    /* buttons */
    '--btn-font-style': 'normal',
    '--btn-font-decoration': 'none',
    '--btn-font-weight': 'normal',
    // button-primary
    '--btn-primary-main-color': '#d8af64',
    '--btn-primary-additional-color': '#291f0d',
    // buttom-secondary
    '--btn-secondary-main-color': '#afafaf',
    '--btn-secondary-additional-color': '#ffffff',
    // buttom-success
    '--btn-success-main-color': '#14af1d',
    '--btn-success-additional-color': '#ffffff',

    // video-border
    '--video-border': '#d8af64',

    // badge
    '--badge-font-color': '#000000',
    '--badge-bg-color': '#d8af64',

    /* Footer */
    // footer-banner
    '--footer-banner-img-url': 'url(./assets/images/themes/bg-vip-prefooter-david.jpg)',
    '--footer-banner-additional-img-url': './assets/images/themes/vip-david-prefooter-img.png',
    '--footer-banner-bg-color': '#969696',
    // footer-headline
    '--footer-headline-font-color': '#d8af64',
    '--footer-headline-font-style': 'normal',
    '--footer-headline-font-decoration': 'none',
    '--footer-headline-font-weight': '700',
    // footer-paragraph
    '--footer-paragraph-font-color': '#ffffff',
    '--footer-paragraph-font-style': 'normal',
    '--footer-paragraph-font-decoration': 'none',
    '--footer-paragraph-font-weight': 'normal'
  }
}; 

export const vip_fitness: iTheme = {
  name: 'vip-fitness',
  properties: {
    /*** Global ***/ 
    '--headline-font-family': '\'PT Sans\', sans-serif',
    '--subheadline-font-family': '\'PT Sans\', sans-serif',
    '--paragraph-font-family': '\'PT Sans\', sans-serif',
    '--shadow-color': '#00071d6b',
    '--link-font-color': '#00378F',

    /*** Local ***/ 
    // main bg
    '--main-bg-color': '#D6E6FF',

    /* General text */
    // headlines
    '--headline-font-color': '#001029',
    '--headline-font-style': 'normal',
    '--headline-font-decoration': 'none',
    '--headline-font-weight': '700',
    // subheadlines
    '--subheadline-font-color': '#ffffff',
    '--subheadline-font-style': 'normal',
    '--subheadline-font-decoration': 'none',
    '--subheadline-font-weight': '700',
    // paragraphs
    '--paragraph-font-color': '#001029',
    '--paragraph-font-style': 'normal',
    '--paragraph-font-decoration': 'none',
    '--paragraph-font-weight': 'normal',

    /* top-banner */
    // top-banner-global
    // '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
    // top-banner-headline
    '--top-banner-headline-font-color': '#FFFFFF',
    '--top-banner-headline-font-style': 'normal',
    '--top-banner-headline-font-decoration': 'none',
    '--top-banner-headline-font-weight': '700',
    // top-banner-subheadline
    '--top-banner-subheadline-font-color': '#FFFFFF',
    '--top-banner-subheadline-font-style': 'normal',
    '--top-banner-subheadline-font-decoration': 'none',
    '--top-banner-subheadline-font-weight': '700',    
    // top-banner-paragraph
    '--top-banner-paragraph-font-color': '#ffffff',
    '--top-banner-paragraph-font-style': 'normal',
    '--top-banner-paragraph-font-decoration': 'none',
    '--top-banner-paragraph-font-weight': 'normal',
    // top-banner-img
    '--top-banner-img-url': 'url(./assets/images/themes/vip-fitness-header.jpg)',
    '--top-banner-bg-color': '#00378fbf',

    // global-nav 
    '--nav-bg-color': '#3381FF',
    '--nav-font-color': '#ffffff',
    '--nav-font-style': 'normal',
    '--nav-font-decoration': 'none',
    '--nav-font-weight': '700',

    // cards 
    '--card-bg-color': '#3381FF',
    '--card-font-color': '#FFFFFF',
    '--card-font-style': 'normal',
    '--card-font-decoration': 'none',
    '--card-font-weight': 'normal',

    // additional
    '--additional-color': '#001029',
    '--additional-bg-color': '#00378F',
    '--snippet-border': '1px solid #b1b8dc',

    /* buttons */
    '--btn-font-style': 'normal',
    '--btn-font-decoration': 'none',
    '--btn-font-weight': 'normal',
    // button-primary
    '--btn-primary-main-color': '#00378F',
    '--btn-primary-additional-color': '#FFFFFF',
    // buttom-secondary
    '--btn-secondary-main-color': '#cbcbcb',
    '--btn-secondary-additional-color': '#ffffff',
    // buttom-success
    '--btn-success-main-color': '#14af1d',
    '--btn-success-additional-color': '#ffffff',

    // video-border
    '--video-border': '#00378F',

    // badge
    '--badge-font-color': '#FFFFFF',
    '--badge-bg-color': '#1a378f',

    /* Footer */
    // footer-banner
    '--footer-banner-img-url': 'url(./assets/images/themes/bg-vip-prefooter-fitness.jpg)',
    '--footer-banner-additional-img-url': './assets/images/themes/vip-fitness-prefooter-img.png',
    '--footer-banner-bg-color': '#10219a57',
    // footer-headline
    '--footer-headline-font-color': '#ffde5c',
    '--footer-headline-font-style': 'normal',
    '--footer-headline-font-decoration': 'none',
    '--footer-headline-font-weight': '700',
    // footer-paragraph
    '--footer-paragraph-font-color': '#ffffff',
    '--footer-paragraph-font-style': 'normal',
    '--footer-paragraph-font-decoration': 'none',
    '--footer-paragraph-font-weight': 'normal',
  }
};

export const vip_baier: iTheme = {
  name: 'vip-baier',
  properties: {
  /*** Global ***/ 
  '--headline-font-family': '\'PT Sans\', sans-serif',
  '--subheadline-font-family': '\'PT Sans\', sans-serif',
  '--paragraph-font-family': '\'PT Sans\', sans-serif',
  '--shadow-color': '#9e7e835c',
  '--link-font-color': '#096e72',

  /*** Local ***/ 
  // main bg
  '--main-bg-color': '#F3F1EB',

  /* General text */
  // headlines
  '--headline-font-color': '#212529',
  '--headline-font-style': 'normal',
  '--headline-font-decoration': 'none',
  '--headline-font-weight': '700',
  // subheadlines
  '--subheadline-font-color': '#212529',
  '--subheadline-font-style': 'normal',
  '--subheadline-font-decoration': 'none',
  '--subheadline-font-weight': '700',
  // paragraphs
  '--paragraph-font-color': '#000000',
  '--paragraph-font-style': 'normal',
  '--paragraph-font-decoration': 'none',
  '--paragraph-font-weight': 'normal',

  /* top-banner */
  // top-banner-global
  // '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
  // top-banner-headline
  '--top-banner-headline-font-color': '#f2dfe2',
  '--top-banner-headline-font-style': 'normal',
  '--top-banner-headline-font-decoration': 'none',
  '--top-banner-headline-font-weight': '700',
  // top-banner-subheadline
  '--top-banner-subheadline-font-color': '#f2dfe2',
  '--top-banner-subheadline-font-style': 'normal',
  '--top-banner-subheadline-font-decoration': 'none',
  '--top-banner-subheadline-font-weight': '700',
  // top-banner-paragraph
  '--top-banner-paragraph-font-color': '#f2dfe2',
  '--top-banner-paragraph-font-style': 'normal',
  '--top-banner-paragraph-font-decoration': 'none',
  '--top-banner-paragraph-font-weight': 'normal',
  // top-banner-img
  '--top-banner-img-url': 'url(./assets/images/themes/vip-baier-header.jpg)',
  '--top-banner-bg-color': '#352c2d',

  // global-nav 
  '--nav-bg-color': '#f2dfe2',
  '--nav-font-color': '#03989e',
  '--nav-font-style': 'normal',
  '--nav-font-decoration': 'none',
  '--nav-font-weight': '700',

  // cards 
  '--card-bg-color': '#f2dfe2',
  '--card-font-color': '#000000',
  '--card-font-style': 'normal',
  '--card-font-decoration': 'none',
  '--card-font-weight': 'normal',

  // additional
  '--additional-color': '#22999e',
  '--additional-bg-color': '#03989e',
  '--snippet-border': '1px solid #eac3c9',

  /* buttons */
  '--btn-font-style': 'normal',
  '--btn-font-decoration': 'none',
  '--btn-font-weight': 'normal',
  // button-primary
  '--btn-primary-main-color': '#03989e',
  '--btn-primary-additional-color': '#FFFFFF',
  // buttom-secondary
  '--btn-secondary-main-color': '#4f4f4f',
  '--btn-secondary-additional-color': '#ffffff',
  // buttom-success
  '--btn-success-main-color': '#14af1d',
  '--btn-success-additional-color': '#ffffff',

  // video-border
  '--video-border': '#03989e',

  // badge
  '--badge-font-color': '#212529',
  '--badge-bg-color': '#f2dfe2',

  /* Footer */
  // footer-banner
  '--footer-banner-img-url': 'url(./assets/images/themes/bg-vip-prefooter-baier.jpg)',
  '--footer-banner-additional-img-url': './assets/images/themes/vip-baier-prefooter-img.png',
  '--footer-banner-bg-color': '#daccd2',
  // footer-headline
  '--footer-headline-font-color': '#212529',
  '--footer-headline-font-style': 'normal',
  '--footer-headline-font-decoration': 'none',
  '--footer-headline-font-weight': '700',
  // footer-paragraph
  '--footer-paragraph-font-color': '#03989e',
  '--footer-paragraph-font-style': 'normal',
  '--footer-paragraph-font-decoration': 'none',
  '--footer-paragraph-font-weight': 'normal',
  }
};

export const vip_energy: iTheme = {
  name: 'vip-energy',
  properties: {
    /*** Global ***/ 
    '--headline-font-family': '\'Lora,Georgia,Times New Roman\', serif',
    '--subheadline-font-family': '\'Raleway\', sans-serif',
    '--paragraph-font-family': '\'PT Sans\', sans-serif',
    '--shadow-color': '#171717ba',
    '--link-font-color': '#987323',

    /*** Local ***/ 
    // main bg
    '--main-bg-color': '#dee6e8',

    /* General text */
    // headlines
    '--headline-font-color': '#E5BF6C',
    '--headline-font-style': 'normal',
    '--headline-font-decoration': 'none',
    '--headline-font-weight': '700',
    // subheadlines
    '--subheadline-font-color': '#987322',
    '--subheadline-font-style': 'normal',
    '--subheadline-font-decoration': 'none',
    '--subheadline-font-weight': '700',
    // paragraphs
    '--paragraph-font-color': '#000000',
    '--paragraph-font-style': 'normal',
    '--paragraph-font-decoration': 'none',
    '--paragraph-font-weight': 'normal',

    /* top-banner */
    // top-banner-global
    // '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
    // top-banner-headline
    '--top-banner-headline-font-color': '#ffffff',
    '--top-banner-headline-font-style': 'italic',
    '--top-banner-headline-font-decoration': 'none',
    '--top-banner-headline-font-weight': '700',
    // top-banner-subheadline
    '--top-banner-subheadline-font-color': '#E5BF6C',
    '--top-banner-subheadline-font-style': 'normal',
    '--top-banner-subheadline-font-decoration': 'none',
    '--top-banner-subheadline-font-weight': '700',  
    // top-banner-paragraph
    '--top-banner-paragraph-font-color': '#ffffff',
    '--top-banner-paragraph-font-style': 'normal',
    '--top-banner-paragraph-font-decoration': 'none',
    '--top-banner-paragraph-font-weight': 'normal',
    // top-banner-img
    '--top-banner-img-url': 'url(./assets/images/themes/vip-baier-header.jpg)',
    '--top-banner-bg-color': '#040404bf',

    // global-nav 
    '--nav-bg-color': '#000000',
    '--nav-font-color': '#ffffff',
    '--nav-font-style': 'normal',
    '--nav-font-decoration': 'none',
    '--nav-font-weight': '700',

    // cards 
    '--card-bg-color': '#000000',
    '--card-font-color': '#E5BF6C',
    '--card-font-style': 'normal',
    '--card-font-decoration': 'none',
    '--card-font-weight': 'normal',

    // additional
    '--additional-color': '#ffffff',
    '--additional-bg-color': '#E5BF6C',
    '--snippet-border': '1px solid #382e1c',

    /* buttons */
    '--btn-font-style': 'normal',
    '--btn-font-decoration': 'none',
    '--btn-font-weight': '700',
    // button-primary
    '--btn-primary-main-color': '#E5BF6C',
    '--btn-primary-additional-color': '#000000',
    // buttom-secondary
    '--btn-secondary-main-color': '#939393',
    '--btn-secondary-additional-color': '#ffffff',
    // buttom-success
    '--btn-success-main-color': '#14af1d',
    '--btn-success-additional-color': '#ffffff',

    // video-border
    '--video-border': '#d8af64',

    // badge
    '--badge-font-color': '#987322',
    '--badge-bg-color': '#000000',

    /* Footer */
    // footer-banner
    '--footer-banner-img-url': 'url(./assets/images/themes/bg-vip-prefooter-baier.jpg)',
    '--footer-banner-additional-img-url': './assets/images/themes/vip-baier-prefooter-img.png',
    '--footer-banner-bg-color': '#0c0c0c',
    // footer-headline
    '--footer-headline-font-color': '#E5BF6C',
    '--footer-headline-font-style': 'normal',
    '--footer-headline-font-decoration': 'none',
    '--footer-headline-font-weight': '700',
    // footer-paragraph
    '--footer-paragraph-font-color': '#ffffff',
    '--footer-paragraph-font-style': 'normal',
    '--footer-paragraph-font-decoration': 'none',
    '--footer-paragraph-font-weight': 'normal',
  }
};

export const vip_jiufit: iTheme = {
  name: 'vip-jiufit',
  properties: {
    /*** Global ***/ 
    '--headline-font-family': '\'Oswald\', sans-serif',
    '--subheadline-font-family': '\'Oswald\', sans-serif',
    '--paragraph-font-family': '\'Old Standard TT\', sans-serif',
    '--shadow-color': '#1a203191',
    '--link-font-color': '#ff5e00',

    /*** Local ***/ 
    // main bg
    '--main-bg-color': '#fff9f2',

    /* General text */
    // headlines
    '--headline-font-color': '#ff5e00',
    '--headline-font-style': 'normal',
    '--headline-font-decoration': 'none',
    '--headline-font-weight': '700',
    // subheadlines
    '--subheadline-font-color': '#ffffff',
    '--subheadline-font-style': 'normal',
    '--subheadline-font-decoration': 'none',
    '--subheadline-font-weight': '700',
    // paragraphs
    '--paragraph-font-color': '#000000',
    '--paragraph-font-style': 'normal',
    '--paragraph-font-decoration': 'none',
    '--paragraph-font-weight': 'normal',

    /* top-banner */
    // top-banner-global
    // '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
    // top-banner-headline
    '--top-banner-headline-font-color': '#ffffff',
    '--top-banner-headline-font-style': 'normal',
    '--top-banner-headline-font-decoration': 'none',
    '--top-banner-headline-font-weight': '700',
    // top-banner-subheadline
    '--top-banner-subheadline-font-color': '#ffffff',
    '--top-banner-subheadline-font-style': 'normal',
    '--top-banner-subheadline-font-decoration': 'none',
    '--top-banner-subheadline-font-weight': '700',  
    // top-banner-paragraph
    '--top-banner-paragraph-font-color': '#ffffff',
    '--top-banner-paragraph-font-style': 'normal',
    '--top-banner-paragraph-font-decoration': 'none',
    '--top-banner-paragraph-font-weight': 'normal',
    // top-banner-img
    '--top-banner-img-url': 'url(./assets/images/themes/vip-jiufit-header.jpg)',
    '--top-banner-bg-color': '#040404bf',

    // global-nav 
    '--nav-bg-color': '#000080',
    '--nav-font-color': '#ffffff',
    '--nav-font-style': 'normal',
    '--nav-font-decoration': 'none',
    '--nav-font-weight': '700',

    // cards 
    '--card-bg-color': '#000080',
    '--card-font-color': '#FFFFFF',
    '--card-font-style': 'normal',
    '--card-font-decoration': 'none',
    '--card-font-weight': 'normal',

    // additional
    '--additional-color': '#ff5e00',
    '--additional-bg-color': '#ff5e00',
    '--snippet-border': '1px solid #35328a',

    /* buttons */
    '--btn-font-style': 'normal',
    '--btn-font-decoration': 'none',
    '--btn-font-weight': '700',
    // button-primary
    '--btn-primary-main-color': '#ff5e00',
    '--btn-primary-additional-color': '#ffffff',
    // buttom-secondary
    '--btn-secondary-main-color': '#b9b9b9',
    '--btn-secondary-additional-color': '#ffffff',
    // buttom-success
    '--btn-success-main-color': '#14af1d',
    '--btn-success-additional-color': '#ffffff',

    // video-border
    '--video-border': '#ff5e00',

    // badge
    '--badge-font-color': '#ffffff',
    '--badge-bg-color': '#000080',

    /* Footer */
    // footer-banner
    '--footer-banner-img-url': 'linear-gradient(134deg,navy,#ff7d00,navy)',
    '--footer-banner-additional-img-url': './assets/images/themes/vip-jiufit-prefooter-img.png',
    '--footer-banner-bg-color': '',
    // footer-headline
    '--footer-headline-font-color': '#ffffff',
    '--footer-headline-font-style': 'normal',
    '--footer-headline-font-decoration': 'none',
    '--footer-headline-font-weight': '700',
    // footer-paragraph
    '--footer-paragraph-font-color': '#ffffff',
    '--footer-paragraph-font-style': 'normal',
    '--footer-paragraph-font-decoration': 'none',
    '--footer-paragraph-font-weight': 'normal'
  }
};

export const vip_dietrich: iTheme = {
  name: 'vip-dietrich',
  properties: {
    /*** Global ***/ 
    '--headline-font-family': '\'Oswald\', sans-serif',
    '--subheadline-font-family': '\'Oswald\', sans-serif',
    '--paragraph-font-family': '\'Old Standard TT\', sans-serif',
    '--shadow-color': '#2b325445',
    '--link-font-color': '#d5031c',

    /*** Local ***/ 
    // main bg
    '--main-bg-color': '#f2f2f2',

    /* General text */
    // headlines
    '--headline-font-color': '#d5031c',
    '--headline-font-style': 'normal',
    '--headline-font-decoration': 'none',
    '--headline-font-weight': '700',
    // subheadlines
    '--subheadline-font-color': '#454545',
    '--subheadline-font-style': 'normal',
    '--subheadline-font-decoration': 'none',
    '--subheadline-font-weight': '700',
    // paragraphs
    '--paragraph-font-color': '#454545',
    '--paragraph-font-style': 'normal',
    '--paragraph-font-decoration': 'none',
    '--paragraph-font-weight': 'normal',

    /* top-banner */
    // top-banner-global
    // '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
    // top-banner-headline
    '--top-banner-headline-font-color': '#454545',
    '--top-banner-headline-font-style': 'normal',
    '--top-banner-headline-font-decoration': 'none',
    '--top-banner-headline-font-weight': '700',
    // top-banner-subheadline
    '--top-banner-subheadline-font-color': '#454545',
    '--top-banner-subheadline-font-style': 'normal',
    '--top-banner-subheadline-font-decoration': 'none',
    '--top-banner-subheadline-font-weight': '700',  
    // top-banner-paragraph
    '--top-banner-paragraph-font-color': '#454545',
    '--top-banner-paragraph-font-style': 'normal',
    '--top-banner-paragraph-font-decoration': 'none',
    '--top-banner-paragraph-font-weight': 'normal',
    // top-banner-img
    '--top-banner-img-url': 'url(./assets/images/themes/vip-dietrich-header.jpg)',
    '--top-banner-bg-color': '#ffffffd6',

    // global-nav 
    '--nav-bg-color': '#ffffff',
    '--nav-font-color': '#252525',
    '--nav-font-style': 'normal',
    '--nav-font-decoration': 'none',
    '--nav-font-weight': '700',

    // cards 
    '--card-bg-color': '#ffffff',
    '--card-font-color': '#666666',
    '--card-font-style': 'normal',
    '--card-font-decoration': 'none',
    '--card-font-weight': 'normal',

    // additional
    '--additional-color': '#d5031c',
    '--additional-bg-color': '#d5031c',
    '--snippet-border': '1px solid #f9dee1',

    /* buttons */
    '--btn-font-style': 'normal',
    '--btn-font-decoration': 'none',
    '--btn-font-weight': '700',
    // button-primary
    '--btn-primary-main-color': '#d5031c',
    '--btn-primary-additional-color': '#ffffff',
    // buttom-secondary
    '--btn-secondary-main-color': '#757575',
    '--btn-secondary-additional-color': '#ffffff',
    // buttom-success
    '--btn-success-main-color': '#14af1d',
    '--btn-success-additional-color': '#ffffff',

    // video-border
    '--video-border': '#d5031c',

    // badge
    '--badge-font-color': '#454545',
    '--badge-bg-color': '#ffffff',

    /* Footer */
    // footer-banner
    '--footer-banner-img-url': '',
    '--footer-banner-additional-img-url': './assets/images/themes/vip-dietrich-prefooter-img.png',
    '--footer-banner-bg-color': '#0e0e0e',
    // footer-headline
    '--footer-headline-font-color': '#919191',
    '--footer-headline-font-style': 'normal',
    '--footer-headline-font-decoration': 'none',
    '--footer-headline-font-weight': '700',
    // footer-paragraph
    '--footer-paragraph-font-color': '#454545',
    '--footer-paragraph-font-style': 'normal',
    '--footer-paragraph-font-decoration': 'none',
    '--footer-paragraph-font-weight': 'normal'
  }
};

export const vip_kientzl: iTheme = {
  name: 'vip-kientzl',
  properties: {
/*** Global ***/ 
'--headline-font-family': '\'Oswald\', sans-serif',
'--subheadline-font-family': '\'Oswald\', sans-serif',
'--paragraph-font-family': '\'Old Standard TT\', sans-serif',
'--shadow-color': '#2b325445',
'--link-font-color': '#000000',

/*** Local ***/ 
// main bg
'--main-bg-color': '#ffffff',

/* General text */
// headlines
'--headline-font-color': '#7f6c4c',
'--headline-font-style': 'normal',
'--headline-font-decoration': 'none',
'--headline-font-weight': '100',
// subheadlines
'--subheadline-font-color': '#7f6c4c',
'--subheadline-font-style': 'normal',
'--subheadline-font-decoration': 'none',
'--subheadline-font-weight': '500',
// paragraphs
'--paragraph-font-color': '#7f6c4c',
'--paragraph-font-style': 'normal',
'--paragraph-font-decoration': 'none',
'--paragraph-font-weight': 'normal',

/* top-banner */
// top-banner-global
// '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
// top-banner-headline
'--top-banner-headline-font-color': '#43351e',
'--top-banner-headline-font-style': 'normal',
'--top-banner-headline-font-decoration': 'none',
'--top-banner-headline-font-weight': '100',
// top-banner-subheadline
'--top-banner-subheadline-font-color': '#43351e',
'--top-banner-subheadline-font-style': 'normal',
'--top-banner-subheadline-font-decoration': 'none',
'--top-banner-subheadline-font-weight': '100',  
// top-banner-paragraph
'--top-banner-paragraph-font-color': '#000000',
'--top-banner-paragraph-font-style': 'normal',
'--top-banner-paragraph-font-decoration': 'none',
'--top-banner-paragraph-font-weight': 'normal',
// top-banner-img
'--top-banner-img-url': 'url(./assets/images/themes/vip-kientzl-header.jpg)',
'--top-banner-bg-color': 'rgb(226 204 175)',

// global-nav 
'--nav-bg-color': '#ccac7a',
'--nav-font-color': '#464544',
'--nav-font-style': 'normal',
'--nav-font-decoration': 'none',
'--nav-font-weight': '400',

// cards 
'--card-bg-color': '#ffffff',
'--card-font-color': '#666666',
'--card-font-style': 'normal',
'--card-font-decoration': 'none',
'--card-font-weight': 'normal',

// additional
'--additional-color': '#7f6c4c',
'--additional-bg-color': '#E5BF6C',
'--snippet-border': '1px solid #E5BF6C',

/* buttons */
'--btn-font-style': 'normal',
'--btn-font-decoration': 'none',
'--btn-font-weight': '700',
// button-primary
'--btn-primary-main-color': '#7f6c4c',
'--btn-primary-additional-color': '#ffffff',
// buttom-secondary
'--btn-secondary-main-color': '#919191',
'--btn-secondary-additional-color': '#ffffff',
// buttom-success
'--btn-success-main-color': '#14af1d',
'--btn-success-additional-color': '#ffffff',

// video-border
'--video-border': '#7f6c4c',

// badge
'--badge-font-color': '#454545',
'--badge-bg-color': '#ffffff',

/* Footer */
// footer-banner
'--footer-banner-img-url': '',
'--footer-banner-additional-img-url': './assets/images/themes/vip-kientzl-prefooter-img.png',
'--footer-banner-bg-color': '#AB9978',
// footer-headline
'--footer-headline-font-color': '#ffffff',
'--footer-headline-font-style': 'normal',
'--footer-headline-font-decoration': 'none',
'--footer-headline-font-weight': 'normal',
// footer-paragraph
'--footer-paragraph-font-color': '#ffffff',
'--footer-paragraph-font-style': 'normal',
'--footer-paragraph-font-decoration': 'none',
'--footer-paragraph-font-weight': 'normal'
  }
};

export const vip_kogler: iTheme = {
  name: 'vip-kogler',
  properties: {
/*** Global ***/ 
'--headline-font-family': '\'Oswald\', sans-serif',
'--subheadline-font-family': '\'Oswald\', sans-serif',
'--paragraph-font-family': '\'Old Standard TT\', sans-serif',
'--shadow-color': '#5c49177d',
'--link-font-color': '#000000',

/*** Local ***/ 
// main bg
'--main-bg-color': '#ffffff',

/* General text */
// headlines
'--headline-font-color': '#1d1d1d',
'--headline-font-style': 'normal',
'--headline-font-decoration': 'none',
'--headline-font-weight': '600',
// subheadlines
'--subheadline-font-color': '#1d1d1d',
'--subheadline-font-style': 'normal',
'--subheadline-font-decoration': 'none',
'--subheadline-font-weight': '600',
// paragraphs
'--paragraph-font-color': '#000000',
'--paragraph-font-style': 'normal',
'--paragraph-font-decoration': 'none',
'--paragraph-font-weight': 'normal',

/* top-banner */
// top-banner-global
// '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
// top-banner-headline
'--top-banner-headline-font-color': '#D7AE40',
'--top-banner-headline-font-style': 'normal',
'--top-banner-headline-font-decoration': 'none',
'--top-banner-headline-font-weight': '600',
// top-banner-subheadline
'--top-banner-subheadline-font-color': '#ffffff',
'--top-banner-subheadline-font-style': 'normal',
'--top-banner-subheadline-font-decoration': 'none',
'--top-banner-subheadline-font-weight': '600',  
// top-banner-paragraph
'--top-banner-paragraph-font-color': '#ffffff',
'--top-banner-paragraph-font-style': 'normal',
'--top-banner-paragraph-font-decoration': 'none',
'--top-banner-paragraph-font-weight': 'normal',
// top-banner-img
'--top-banner-img-url': 'url(./assets/images/themes/vip-kogler-header.jpg)',
'--top-banner-bg-color': '#1C1C1C',

// global-nav 
'--nav-bg-color': '#D7AE40',
'--nav-font-color': '#464544',
'--nav-font-style': 'normal',
'--nav-font-decoration': 'none',
'--nav-font-weight': '400',

// cards 
'--card-bg-color': '#f0f0f0',
'--card-font-color': '#1C1C1C',
'--card-font-style': 'normal',
'--card-font-decoration': 'none',
'--card-font-weight': 'normal',

// additional
'--additional-color': '#D7AE40',
'--additional-bg-color': '#E5BF6C',
'--snippet-border': '1px solid #1C1C1C',

/* buttons */
'--btn-font-style': 'normal',
'--btn-font-decoration': 'none',
'--btn-font-weight': '700',
// button-primary
'--btn-primary-main-color': '#1C1C1C',
'--btn-primary-additional-color': '#ffffff',
// buttom-secondary
'--btn-secondary-main-color': '#636363',
'--btn-secondary-additional-color': '#ffffff',
// buttom-success
'--btn-success-main-color': '#14af1d',
'--btn-success-additional-color': '#ffffff',

// video-border
'--video-border': '#1C1C1C',

// badge
'--badge-font-color': '#454545',
'--badge-bg-color': '#ffffff',

/* Footer */
// footer-banner
'--footer-banner-img-url': 'url(./assets/images/themes/bg-vip-prefooter-kogler.jpg)',
'--footer-banner-additional-img-url': './assets/images/themes/vip-kogler-prefooter-img.png',
'--footer-banner-bg-color': '#272727',
// footer-headline
'--footer-headline-font-color': '#ffffff',
'--footer-headline-font-style': 'normal',
'--footer-headline-font-decoration': 'none',
'--footer-headline-font-weight': 'normal',
// footer-paragraph
'--footer-paragraph-font-color': '#ffffff',
'--footer-paragraph-font-style': 'normal',
'--footer-paragraph-font-decoration': 'none',
'--footer-paragraph-font-weight': 'normal'
  }
};

export const vip_kogler_kurse: iTheme = {
  name: 'vip-kogler-kurse',
  properties: {
/*** Global ***/ 
'--headline-font-family': '\'Oswald\', sans-serif',
'--subheadline-font-family': '\'Oswald\', sans-serif',
'--paragraph-font-family': '\'Old Standard TT\', sans-serif',
'--shadow-color': '#43496f73',
'--link-font-color': '#000000',

/*** Local ***/ 
// main bg
'--main-bg-color': '#ffffff',

/* General text */
// headlines
'--headline-font-color': '#123462',
'--headline-font-style': 'normal',
'--headline-font-decoration': 'none',
'--headline-font-weight': '600',
// subheadlines
'--subheadline-font-color': '#123462',
'--subheadline-font-style': 'normal',
'--subheadline-font-decoration': 'none',
'--subheadline-font-weight': '600',
// paragraphs
'--paragraph-font-color': '#123462',
'--paragraph-font-style': 'normal',
'--paragraph-font-decoration': 'none',
'--paragraph-font-weight': 'normal',

/* top-banner */
// top-banner-global
// '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
// top-banner-headline
'--top-banner-headline-font-color': '#123462',
'--top-banner-headline-font-style': 'normal',
'--top-banner-headline-font-decoration': 'none',
'--top-banner-headline-font-weight': '600',
// top-banner-subheadline
'--top-banner-subheadline-font-color': '#123462',
'--top-banner-subheadline-font-style': 'normal',
'--top-banner-subheadline-font-decoration': 'none',
'--top-banner-subheadline-font-weight': '600',  
// top-banner-paragraph
'--top-banner-paragraph-font-color': '#123462',
'--top-banner-paragraph-font-style': 'normal',
'--top-banner-paragraph-font-decoration': 'none',
'--top-banner-paragraph-font-weight': 'normal',
// top-banner-img
'--top-banner-img-url': 'url(./assets/images/themes/vip-kogler-header.jpg)',
'--top-banner-bg-color': '#f0f0f0',

// global-nav 
'--nav-bg-color': '#D7AE40',
'--nav-font-color': '#464544',
'--nav-font-style': 'normal',
'--nav-font-decoration': 'none',
'--nav-font-weight': '400',

// cards 
'--card-bg-color': '#f0f0f0',
'--card-font-color': '#123462',
'--card-font-style': 'normal',
'--card-font-decoration': 'none',
'--card-font-weight': 'normal',

// additional
'--additional-color': '#214c85',
'--additional-bg-color': '#E5BF6C',
'--snippet-border': '1px solid #123462',

/* buttons */
'--btn-font-style': 'normal',
'--btn-font-decoration': 'none',
'--btn-font-weight': '700',
// button-primary
'--btn-primary-main-color': '#123462',
'--btn-primary-additional-color': '#ffffff',
// buttom-secondary
'--btn-secondary-main-color': '#515151',
'--btn-secondary-additional-color': '#ffffff',
// buttom-success
'--btn-success-main-color': '#14af1d',
'--btn-success-additional-color': '#ffffff',

// video-border
'--video-border': '#123462',

// badge
'--badge-font-color': '#454545',
'--badge-bg-color': '#ffffff',

/* Footer */
// footer-banner
'--footer-banner-img-url': 'url(./assets/images/themes/bg-vip-prefooter-kogler.jpg)',
'--footer-banner-additional-img-url': './assets/images/themes/vip-kogler-prefooter-img.png',
'--footer-banner-bg-color': '#033e8e',
// footer-headline
'--footer-headline-font-color': '#ffffff',
'--footer-headline-font-style': 'normal',
'--footer-headline-font-decoration': 'none',
'--footer-headline-font-weight': 'normal',
// footer-paragraph
'--footer-paragraph-font-color': '#ffffff',
'--footer-paragraph-font-style': 'normal',
'--footer-paragraph-font-decoration': 'none',
'--footer-paragraph-font-weight': 'normal'
  }
};

export const vip_lobermeyer: iTheme = {
  name: 'vip-lobermeyer',
  properties: {
/*** Global ***/ 
'--headline-font-family': '\'Oswald\', sans-serif',
'--subheadline-font-family': '\'Oswald\', sans-serif',
'--paragraph-font-family': '\'Old Standard TT\', sans-serif',
'--shadow-color': '#43496f73',
'--link-font-color': '#123462',

/*** Local ***/ 
// main bg
'--main-bg-color': '#ffffff',

/* General text */
// headlines
'--headline-font-color': '#123462',
'--headline-font-style': 'normal',
'--headline-font-decoration': 'none',
'--headline-font-weight': '600',
// subheadlines
'--subheadline-font-color': '#123462',
'--subheadline-font-style': 'normal',
'--subheadline-font-decoration': 'none',
'--subheadline-font-weight': '600',
// paragraphs
'--paragraph-font-color': '#123462',
'--paragraph-font-style': 'normal',
'--paragraph-font-decoration': 'none',
'--paragraph-font-weight': 'normal',

/* top-banner */
// top-banner-global
// '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
// top-banner-headline
'--top-banner-headline-font-color': '#ffffff',
'--top-banner-headline-font-style': 'normal',
'--top-banner-headline-font-decoration': 'none',
'--top-banner-headline-font-weight': '600',
// top-banner-subheadline
'--top-banner-subheadline-font-color': '#ffffff',
'--top-banner-subheadline-font-style': 'normal',
'--top-banner-subheadline-font-decoration': 'none',
'--top-banner-subheadline-font-weight': '600',  
// top-banner-paragraph
'--top-banner-paragraph-font-color': '#ffffff',
'--top-banner-paragraph-font-style': 'normal',
'--top-banner-paragraph-font-decoration': 'none',
'--top-banner-paragraph-font-weight': 'normal',
// top-banner-img
'--top-banner-img-url': 'url(./assets/images/themes/vip-lobermeyer-header.jpg)',
'--top-banner-bg-color': '#0e275ec9',

// global-nav 
'--nav-bg-color': '#ed5101',
'--nav-font-color': '#0e265e',
'--nav-font-style': 'normal',
'--nav-font-decoration': 'none',
'--nav-font-weight': '400',

// cards 
'--card-bg-color': '#ffffff',
'--card-font-color': '#0e265e',
'--card-font-style': 'normal',
'--card-font-decoration': 'none',
'--card-font-weight': 'normal',

// additional
'--additional-color': '#ed5101',
'--additional-bg-color': '#ed5101',
'--snippet-border': '1px solid #ed5101',

/* buttons */
'--btn-font-style': 'normal',
'--btn-font-decoration': 'none',
'--btn-font-weight': '700',
// button-primary
'--btn-primary-main-color': '#0e265e',
'--btn-primary-additional-color': '#ffffff',
// buttom-secondary
'--btn-secondary-main-color': '#515151',
'--btn-secondary-additional-color': '#ffffff',
// buttom-success
'--btn-success-main-color': '#14af1d',
'--btn-success-additional-color': '#ffffff',

// video-border
'--video-border': '#0e265e',

// badge
'--badge-font-color': '#454545',
'--badge-bg-color': '#ffffff',

/* Footer */
// footer-banner
'--footer-banner-img-url': 'url(./assets/images/themes/bg-vip-prefooter-lobermeyer.jpg)',
'--footer-banner-additional-img-url': './assets/images/themes/vip-lobermeyer-prefooter-img.png',
'--footer-banner-bg-color': '#071025',
// footer-headline
'--footer-headline-font-color': '#ffffff',
'--footer-headline-font-style': 'normal',
'--footer-headline-font-decoration': 'none',
'--footer-headline-font-weight': 'normal',
// footer-paragraph
'--footer-paragraph-font-color': '#ffffff',
'--footer-paragraph-font-style': 'normal',
'--footer-paragraph-font-decoration': 'none',
'--footer-paragraph-font-weight': 'normal'
  }
};

export const vip_daniela: iTheme = {
  name: 'vip-daniela',
  properties: {
    /*** Global ***/ 
    '--headline-font-family': '\'Oswald\', sans-serif',
    '--subheadline-font-family': '\'Oswald\', sans-serif',
    '--paragraph-font-family': '\'Old Standard TT\', sans-serif',
    '--shadow-color': '#2b325445',
    '--link-font-color': '#972983',

    /*** Local ***/ 
    // main bg
    '--main-bg-color': '#fffbfe',

    /* General text */
    // headlines
    '--headline-font-color': '#333333',
    '--headline-font-style': 'normal',
    '--headline-font-decoration': 'none',
    '--headline-font-weight': '300',
    // subheadlines
    '--subheadline-font-color': '#333333',
    '--subheadline-font-style': 'normal',
    '--subheadline-font-decoration': 'none',
    '--subheadline-font-weight': '500',
    // paragraphs
    '--paragraph-font-color': '#333333',
    '--paragraph-font-style': 'normal',
    '--paragraph-font-decoration': 'none',
    '--paragraph-font-weight': '500',

    /* top-banner */
    // top-banner-global
    // '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
    // top-banner-headline
    '--top-banner-headline-font-color': '#454545',
    '--top-banner-headline-font-style': 'normal',
    '--top-banner-headline-font-decoration': 'none',
    '--top-banner-headline-font-weight': '300',
    // top-banner-subheadline
    '--top-banner-subheadline-font-color': '#454545',
    '--top-banner-subheadline-font-style': 'normal',
    '--top-banner-subheadline-font-decoration': 'none',
    '--top-banner-subheadline-font-weight': '300',  
    // top-banner-paragraph
    '--top-banner-paragraph-font-color': '#454545',
    '--top-banner-paragraph-font-style': 'normal',
    '--top-banner-paragraph-font-decoration': 'none',
    '--top-banner-paragraph-font-weight': 'normal',
    // top-banner-img
    '--top-banner-img-url': 'url(./assets/images/themes/vip-daniela-header.jpg)',
    '--top-banner-bg-color': '#ffffffd6',

    // global-nav 
    '--nav-bg-color': '#ffffff',
    '--nav-font-color': '#454545',
    '--nav-font-style': 'normal',
    '--nav-font-decoration': 'none',
    '--nav-font-weight': '500',

    // cards 
    '--card-bg-color': '#ffffff',
    '--card-font-color': '#454545',
    '--card-font-style': 'normal',
    '--card-font-decoration': 'none',
    '--card-font-weight': 'normal',

    // additional
    '--additional-color': '#972983',
    '--additional-bg-color': '#25a63f',
    '--snippet-border': '1px solid #ffe0f9',

    /* buttons */
    '--btn-font-style': 'normal',
    '--btn-font-decoration': 'none',
    '--btn-font-weight': '700',
    // button-primary
    '--btn-primary-main-color': '#972983',
    '--btn-primary-additional-color': '#ffffff',
    // buttom-secondary
    '--btn-secondary-main-color': '#6e6d6d',
    '--btn-secondary-additional-color': '#ffffff',
    // buttom-success
    '--btn-success-main-color': '#14af1d',
    '--btn-success-additional-color': '#ffffff',

    // video-border
    '--video-border': '#972983',

    // badge
    '--badge-font-color': '#454545',
    '--badge-bg-color': '#ffffff',

    /* Footer */
    // footer-banner
    '--footer-banner-img-url': 'url(./assets/images/themes/bg-vip-prefooter-daniela.jpg)',
    '--footer-banner-additional-img-url': './assets/images/themes/vip-daniela-prefooter-img.png',
    '--footer-banner-bg-color': '#ffffffc7',
    // footer-headline
    '--footer-headline-font-color': '#919191',
    '--footer-headline-font-style': 'normal',
    '--footer-headline-font-decoration': 'none',
    '--footer-headline-font-weight': '700',
    // footer-paragraph
    '--footer-paragraph-font-color': '#454545',
    '--footer-paragraph-font-style': 'normal',
    '--footer-paragraph-font-decoration': 'none',
    '--footer-paragraph-font-weight': 'normal'
}
};

// new themes
export const nature_energy: iTheme = {
  name: 'nature-energy',
  properties: {
    /*** Global ***/ 
    '--headline-font-family': '\'Quicksand\', sans-serif',
    '--subheadline-font-family': '\'Quicksand\', sans-serif',
    '--paragraph-font-family': '\'Quicksand\', sans-serif',
    '--shadow-color': 'rgba(104,104,104,0.44)',
    '--link-font-color': '#27ae60',

    /*** Local ***/ 
    // main bg
    '--main-bg-color': '#e8e8e8',

    /* General text */
    // headlines
    '--headline-font-color': '#27ae60',
    '--headline-font-style': 'normal',
    '--headline-font-decoration': 'none',
    '--headline-font-weight': 'bold',
    // subheadlines
    '--subheadline-font-color': '#4A4A4A',
    '--subheadline-font-style': 'normal',
    '--subheadline-font-decoration': 'none',
    '--subheadline-font-weight': 'bold',
    // paragraphs
    '--paragraph-font-color': '#333333',
    '--paragraph-font-style': 'normal',
    '--paragraph-font-decoration': 'none',
    '--paragraph-font-weight': 'normal',

    /* top-banner */
    // top-banner-global
    // '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
    // top-banner-headline
    '--top-banner-headline-font-color': '#27ae60',
    '--top-banner-headline-font-style': 'normal',
    '--top-banner-headline-font-decoration': 'none',
    '--top-banner-headline-font-weight': 'bold',
    // top-banner-subheadline
    '--top-banner-subheadline-font-color': '#27ae60',
    '--top-banner-subheadline-font-style': 'normal',
    '--top-banner-subheadline-font-decoration': 'none',
    '--top-banner-subheadline-font-weight': 'bold',  
    // top-banner-paragraph
    '--top-banner-paragraph-font-color': '#1e1e1e',
    '--top-banner-paragraph-font-style': 'normal',
    '--top-banner-paragraph-font-decoration': 'none',
    '--top-banner-paragraph-font-weight': 'normal',
    // top-banner-img
    '--top-banner-img-url': 'url(./assets/images/themes/nature-energy/nature-background-header.jpg)',
    '--top-banner-bg-color': 'rgba(236,255,227,0.65)',

    // global-nav 
    '--nav-bg-color': '#2ecc71',
    '--nav-font-color': '#ffffff',
    '--nav-font-style': 'normal',
    '--nav-font-decoration': 'none',
    '--nav-font-weight': 'bold',

    // cards 
    '--card-bg-color': '#ffffff',
    '--card-font-color': '#1e1e1e',
    '--card-font-style': 'normal',
    '--card-font-decoration': 'none',
    '--card-font-weight': 'normal',

    // additional
    '--additional-color': '#27ae60',
    '--additional-bg-color': '#27ae60',
    // '--snippet-border': '1px solid #ffe0f9',

    /* buttons */
    '--btn-font-style': 'normal',
    '--btn-font-decoration': 'none',
    '--btn-font-weight': 'bold',
    // button-primary
    '--btn-primary-main-color': '#2ecc71',
    '--btn-primary-additional-color': '#ffffff',
    // button-secondary
    '--btn-secondary-main-color': '#2ecc71',
    '--btn-secondary-additional-color': '#ffffff',
    // button-success
    '--btn-success-main-color': '#e0fcec',
    '--btn-success-additional-color': '#2ecc71',

    // video-border
    '--video-border': '#2ecc71',

    // badge
    '--badge-font-color': '#4A4A4A',
    '--badge-bg-color': '#ffffff',

    /* Footer */
    // footer-banner
    '--footer-banner-img-url': 'url(./assets/images/themes/nature-energy/nature-footer-bg.jpg)',
    '--footer-banner-additional-img-url': './assets/images/themes/nature-energy/nature-footer.png',
    '--footer-banner-bg-color': 'rgba(236,255,227,0.65)',
    // footer-headline
    '--footer-headline-font-color': '#4A4A4A',
    '--footer-headline-font-style': 'normal',
    '--footer-headline-font-decoration': 'none',
    '--footer-headline-font-weight': 'bold',
    // footer-paragraph
    '--footer-paragraph-font-color': '#4A4A4A',
    '--footer-paragraph-font-style': 'normal',
    '--footer-paragraph-font-decoration': 'none',
    '--footer-paragraph-font-weight': 'bold',

    //Preset images
    '--img-logo-login':     './assets/images/themes/nature-energy/nature-img-logo-green.png',
    '--img-logo-dashboard': './assets/images/themes/nature-energy/nature-img-logo-white.png',
    '--img-favicon':        './assets/images/themes/nature-energy/nature-login-img.jpg',
    '--img-login-desktop':  './assets/images/themes/nature-energy/nature-energy-login.jpg',
    '--img-login-mobile':   '../assets/images/themes/nature-energy/nature-login-img.jpg',
    '--img-user-avatar':    './assets/images/themes/nature-energy/nature-login-img.jpg',
    '--img-shoutout-box':   './assets/images/themes/nature-energy/nature-shoutout.png',
    '--img-cml-wrp':        './assets/images/themes/nature-energy/nature-footer-bg.jpg'
  }
};

export const business_classic: iTheme = {
  name: 'business-classic',
  properties: {
    /*** Global ***/ 
    '--headline-font-family': '\'Roboto Slab\', serif',
    '--subheadline-font-family': '\'Roboto Slab\', serif',
    '--paragraph-font-family': '\'Jaldi\', sans-serif',
    '--shadow-color': 'rgba(52,152,219,0.2)',
    '--link-font-color': '#3498db',

    /*** Local ***/ 
    // main bg
    '--main-bg-color': '#F0F3F4',

    /* General text */
    // headlines
    '--headline-font-color': '#3498db',
    '--headline-font-style': 'normal',
    '--headline-font-decoration': 'none',
    '--headline-font-weight': 'bold',
    // subheadlines
    '--subheadline-font-color': '#4A4A4A',
    '--subheadline-font-style': 'normal',
    '--subheadline-font-decoration': 'none',
    '--subheadline-font-weight': 'bold',
    // paragraphs
    '--paragraph-font-color': '#4A4A4A',
    '--paragraph-font-style': 'normal',
    '--paragraph-font-decoration': 'none',
    '--paragraph-font-weight': 'normal',

    /* top-banner */
    // top-banner-global
    // '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
    // top-banner-headline
    '--top-banner-headline-font-color': '#3498db',
    '--top-banner-headline-font-style': 'normal',
    '--top-banner-headline-font-decoration': 'none',
    '--top-banner-headline-font-weight': 'bold',
    // top-banner-subheadline
    '--top-banner-subheadline-font-color': '#333333',
    '--top-banner-subheadline-font-style': 'normal',
    '--top-banner-subheadline-font-decoration': 'none',
    '--top-banner-subheadline-font-weight': 'normal',  
    // top-banner-paragraph
    '--top-banner-paragraph-font-color': '#333333',
    '--top-banner-paragraph-font-style': 'normal',
    '--top-banner-paragraph-font-decoration': 'none',
    '--top-banner-paragraph-font-weight': 'normal',
    // top-banner-img
    '--top-banner-img-url': 'url(./assets/images/themes/business-classic/business-classic-background-header.jpg)',
    '--top-banner-bg-color': 'rgba(255,255,255,0.84)',

    // global-nav 
    '--nav-bg-color': '#FFFFFF',
    '--nav-font-color': '#3498db',
    '--nav-font-style': 'normal',
    '--nav-font-decoration': 'none',
    '--nav-font-weight': 'bold',

    // cards 
    '--card-bg-color': '#ffffff',
    '--card-font-color': '#333333',
    '--card-font-style': 'normal',
    '--card-font-decoration': 'none',
    '--card-font-weight': 'normal',

    // additional
    '--additional-color': '#4a4a4a',
    '--additional-bg-color': '#3498db',

    /* buttons */
    '--btn-font-style': 'normal',
    '--btn-font-decoration': 'none',
    '--btn-font-weight': 'bold',
    // button-primary
    '--btn-primary-main-color': '#3498db',
    '--btn-primary-additional-color': '#ffffff',
    // button-secondary
    '--btn-secondary-main-color': '#7b7b7b',
    '--btn-secondary-additional-color': '#ffffff',
    // button-success
    '--btn-success-main-color': '#55efc4',
    '--btn-success-additional-color': '#ffffff',

    // video-border
    '--video-border': '#3498db',

    // badge
    '--badge-font-color': '#3498db',
    '--badge-bg-color': '#ffffff',

    /* Footer */
    // footer-banner
    '--footer-banner-img-url': 'url(./assets/images/themes/business-classic/business-classic-background-footer.jpg)',
    '--footer-banner-additional-img-url': './assets/images/themes/business-classic/business-classic-additional-img.jpg',
    '--footer-banner-bg-color': 'rgba(255, 255, 255, 0)',
    // footer-headline
    '--footer-headline-font-color': '#4A4A4A',
    '--footer-headline-font-style': 'normal',
    '--footer-headline-font-decoration': 'none',
    '--footer-headline-font-weight': 'bold',
    // footer-paragraph
    '--footer-paragraph-font-color': '#4A4A4A',
    '--footer-paragraph-font-style': 'normal',
    '--footer-paragraph-font-decoration': 'none',
    '--footer-paragraph-font-weight': 'bold',

    //Preset images
    '--img-logo-login':     './assets/images/themes/business-classic/business-classic-logo-blue.png',
    '--img-logo-dashboard': './assets/images/themes/business-classic/business-classic-logo-blue.png',
    '--img-favicon':        './assets/images/themes/business-classic/business-classic-favicon.webp',
    '--img-login-desktop':  './assets/images/themes/business-classic/business-classic-login-bg.jpg',
    '--img-login-mobile':   './assets/images/themes/business-classic/business-classic-login-mobile.jpg',
    '--img-user-avatar':    './assets/images/themes/business-classic/business-classic-user-avatar.jpg',
    '--img-shoutout-box':   './assets/images/themes/business-classic/calm-relaxing-shoutout.png',
    '--img-cml-wrp':        './assets/images/themes/business-classic/business-classic-cml.jpg'
  }
};

export const business_elegant: iTheme = {
  name: 'business-elegant',
  properties: {
    /*** Global ***/ 
    '--headline-font-family': '\'PT Sans\', serif',
    '--subheadline-font-family': '\'PT Sans\', serif',
    '--paragraph-font-family': '\'PT Sans\', sans-serif',
    '--shadow-color': 'rgba(168,175, 196, 0.5)',
    '--link-font-color': '#000000',

    /*** Local ***/ 
    // main bg
    '--main-bg-color': '#F0F3F4',

    /* General text */
    // headlines
    '--headline-font-color': '#d6a500',
    '--headline-font-style': 'normal',
    '--headline-font-decoration': 'none',
    '--headline-font-weight': 'bold',
    // subheadlines
    '--subheadline-font-color': '#4A4A4A',
    '--subheadline-font-style': 'normal',
    '--subheadline-font-decoration': 'none',
    '--subheadline-font-weight': 'bold',
    // paragraphs
    '--paragraph-font-color': '#4A4A4A',
    '--paragraph-font-style': 'normal',
    '--paragraph-font-decoration': 'none',
    '--paragraph-font-weight': 'normal',

    /* top-banner */
    // top-banner-global
    // '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
    // top-banner-headline
    '--top-banner-headline-font-color': '#d6a500',
    '--top-banner-headline-font-style': 'normal',
    '--top-banner-headline-font-decoration': 'none',
    '--top-banner-headline-font-weight': 'bold',
    // top-banner-subheadline
    '--top-banner-subheadline-font-color': '#4A4A4A',
    '--top-banner-subheadline-font-style': 'normal',
    '--top-banner-subheadline-font-decoration': 'none',
    '--top-banner-subheadline-font-weight': 'bold',  
    // top-banner-paragraph
    '--top-banner-paragraph-font-color': '#4A4A4A',
    '--top-banner-paragraph-font-style': 'normal',
    '--top-banner-paragraph-font-decoration': 'none',
    '--top-banner-paragraph-font-weight': 'normal',
    // top-banner-img
    '--top-banner-img-url': 'url(./assets/images/themes/business-elegant/business-elegant-background-header.jpg)',
    '--top-banner-bg-color': 'rgba(255,255,255,0.76)',

    // global-nav 
    '--nav-bg-color': '#1c2120',
    '--nav-font-color': '#d6a500',
    '--nav-font-style': 'normal',
    '--nav-font-decoration': 'none',
    '--nav-font-weight': 'bold',

    // cards 
    '--card-bg-color': '#ffffff',
    '--card-font-color': '#4A4A4A',
    '--card-font-style': 'normal',
    '--card-font-decoration': 'none',
    '--card-font-weight': 'normal',

    // additional
    '--additional-color': '#d6a500',
    '--additional-bg-color': '#d6a500',
    // '--snippet-border': '1px solid #ffe0f9',

    /* buttons */
    '--btn-font-style': 'normal',
    '--btn-font-decoration': 'none',
    '--btn-font-weight': 'bold',
    // button-primary
    '--btn-primary-main-color': '#d6a500',
    '--btn-primary-additional-color': '#ffffff',
    // button-secondary
    '--btn-secondary-main-color': '#4A4A4A',
    '--btn-secondary-additional-color': '#ffffff',
    // button-success
    '--btn-success-main-color': '#14af1d',
    '--btn-success-additional-color': '#ffffff',

    // video-border
    '--video-border': '#000000',

    // badge
    '--badge-font-color': '#4A4A4A',
    '--badge-bg-color': '#ffffff',

    /* Footer */
    // footer-banner
    '--footer-banner-img-url': 'url(./assets/images/themes/business-elegant/business-elegant-footer-background.jpg)',
    '--footer-banner-additional-img-url': './assets/images/themes/business-elegant/business-elegant-footer-image.png',
    '--footer-banner-bg-color': 'rgba(255,255,255,0.79)',
    // footer-headline
    '--footer-headline-font-color': '#d6a500',
    '--footer-headline-font-style': 'normal',
    '--footer-headline-font-decoration': 'none',
    '--footer-headline-font-weight': 'bold',
    // footer-paragraph
    '--footer-paragraph-font-color': '#4A4A4A',
    '--footer-paragraph-font-style': 'normal',
    '--footer-paragraph-font-decoration': 'none',
    '--footer-paragraph-font-weight': 'normal',
    
    //Preset images
    '--img-logo-login':     './assets/images/themes/business-elegant/business-elegant-logo-yellow.png',
    '--img-logo-dashboard': './assets/images/themes/business-elegant/business-elegant-logo-yellow.png',
    '--img-favicon':        './assets/images/themes/calm-relaxing/calm-relaxing-favicon.webp',
    '--img-login-desktop':  './assets/images/themes/business-elegant/business-elegant-login.jpg',
    '--img-login-mobile':   './assets/images/themes/business-elegant/business-elegant-login-mobile.jpg',
    '--img-user-avatar':    './assets/images/themes/business-elegant/business-elegant-user-avatar.png',
    '--img-shoutout-box':   './assets/images/themes/business-elegant/calm-relaxing-shoutout.png',
    '--img-cml-wrp':        './assets/images/themes/business-elegant/business-elegant-cml.jpg'
  }
};


export const calm_relaxing: iTheme = {
  name: 'calm-relaxing',
  properties: {
    /*** Global ***/ 
    '--headline-font-family': '\'Dosis\', sans-serif',
    '--subheadline-font-family': '\'Dosis\', sans-serif',
    '--paragraph-font-family': '\'Jaldi\', sans-serif',
    '--shadow-color': 'rgba(255,255,255,0)',
    '--link-font-color': '#000000',

    /*** Local ***/ 
    // main bg
    '--main-bg-color': '#fffbed',

    /* General text */
    // headlines
    '--headline-font-color': '#936d27',
    '--headline-font-style': 'normal',
    '--headline-font-decoration': 'none',
    '--headline-font-weight': 'bold',
    // subheadlines
    '--subheadline-font-color': '#936d27',
    '--subheadline-font-style': 'normal',
    '--subheadline-font-decoration': 'none',
    '--subheadline-font-weight': 'bold',
    // paragraphs
    '--paragraph-font-color': '#936d27',
    '--paragraph-font-style': 'normal',
    '--paragraph-font-decoration': 'none',
    '--paragraph-font-weight': 'normal',

    /* top-banner */
    // top-banner-global
    // '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
    // top-banner-headline
    '--top-banner-headline-font-color': '#936d27',
    '--top-banner-headline-font-style': 'normal',
    '--top-banner-headline-font-decoration': 'none',
    '--top-banner-headline-font-weight': 'bold',
    // top-banner-subheadline
    '--top-banner-subheadline-font-color': '#936d27',
    '--top-banner-subheadline-font-style': 'normal',
    '--top-banner-subheadline-font-decoration': 'none',
    '--top-banner-subheadline-font-weight': 'normal',  
    // top-banner-paragraph
    '--top-banner-paragraph-font-color': '#936d27',
    '--top-banner-paragraph-font-style': 'normal',
    '--top-banner-paragraph-font-decoration': 'none',
    '--top-banner-paragraph-font-weight': 'normal',
    // top-banner-img
    '--top-banner-img-url': 'url(./assets/images/themes/calm-relaxing/calm-relaxing-background-header.jpeg)',
    '--top-banner-bg-color': '#ede7d1',

    // global-nav 
    '--nav-bg-color': '#ffeaa7',
    '--nav-font-color': '#936d27',
    '--nav-font-style': 'normal',
    '--nav-font-decoration': 'none',
    '--nav-font-weight': 'bold',

    // cards 
    '--card-bg-color': '#ffeaa7',
    '--card-font-color': '#4A4A4A',
    '--card-font-style': 'normal',
    '--card-font-decoration': 'none',
    '--card-font-weight': 'normal',

    // additional
    '--additional-color': '#936d27',
    '--additional-bg-color': '#e49e1d',

    /* buttons */
    '--btn-font-style': 'normal',
    '--btn-font-decoration': 'none',
    '--btn-font-weight': 'bold',
    // button-primary
    '--btn-primary-main-color': '#fdcb6e',
    '--btn-primary-additional-color': '#936d27',
    // button-secondary
    '--btn-secondary-main-color': '#936d27',
    '--btn-secondary-additional-color': '#ffffff',
    // button-success
    '--btn-success-main-color': 'rgba(147,109,39,0)',
    '--btn-success-additional-color': '#2ecc71',

    // video-border
    '--video-border': '#936d27',

    // badge
    '--badge-font-color': '#ffffff',
    '--badge-bg-color': '#e49e1d',

    /* Footer */
    // footer-banner
    '--footer-banner-img-url': 'url(./assets/images/themes/calm-relaxing/calm-relaxing-footer-background.jpeg)',
    '--footer-banner-additional-img-url': './assets/images/themes/calm-relaxing/calm-relaxing-footer-additional-img.jpg',
    '--footer-banner-bg-color': '#fff5d5',
    // footer-headline
    '--footer-headline-font-color': '#936d27',
    '--footer-headline-font-style': 'normal',
    '--footer-headline-font-decoration': 'none',
    '--footer-headline-font-weight': 'bold',
    // footer-paragraph
    '--footer-paragraph-font-color': '#936d27',
    '--footer-paragraph-font-style': 'normal',
    '--footer-paragraph-font-decoration': 'none',
    '--footer-paragraph-font-weight': 'normal',

    //Preset images
    '--img-logo-login':     './assets/images/themes/calm-relaxing/calm-relaxing-logo.png',
    '--img-logo-dashboard': './assets/images/themes/calm-relaxing/calm-relaxing-logo.png',
    '--img-favicon':        './assets/images/themes/calm-relaxing/calm-relaxing-favicon.webp',
    '--img-login-desktop':  './assets/images/themes/calm-relaxing/calm-relaxing-login-bg.jpg',
    '--img-login-mobile':   './assets/images/themes/calm-relaxing/calm-relaxing-login-mobile.webp',
    '--img-user-avatar':    './assets/images/themes/calm-relaxing/calm-relaxing-user-avatar.png',
    '--img-shoutout-box':   './assets/images/themes/calm-relaxing/calm-relaxing-shoutout.png',
    '--img-cml-wrp':        './assets/images/themes/calm-relaxing/calm-relaxing-cml.jpg'
  }
};

export const calm_relaxing_2: iTheme = {
  name: 'calm-relaxing-2',
  properties: {
    /*** Global ***/ 
    '--headline-font-family': '\'Quicksand\', serif',
    '--subheadline-font-family': '\'Quicksand\', serif',
    '--paragraph-font-family': '\'Montserrat\', sans-serif',
    '--shadow-color': 'rgba(168,175, 196, 0.5)',
    '--link-font-color': '#387efc',

    /*** Local ***/ 
    // main bg
    '--main-bg-color': '#f6ffff',

    /* General text */
    // headlines
    '--headline-font-color': '#0b7575',
    '--headline-font-style': 'normal',
    '--headline-font-decoration': 'none',
    '--headline-font-weight': 'bold',
    // subheadlines
    '--subheadline-font-color': '#00cec9',
    '--subheadline-font-style': 'normal',
    '--subheadline-font-decoration': 'none',
    '--subheadline-font-weight': 'bold',
    // paragraphs
    '--paragraph-font-color': '#0b7575',
    '--paragraph-font-style': 'normal',
    '--paragraph-font-decoration': 'none',
    '--paragraph-font-weight': 'normal',

    /* top-banner */
    // top-banner-global
    // '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
    // top-banner-headline
    '--top-banner-headline-font-color': '#0b7575',
    '--top-banner-headline-font-style': 'normal',
    '--top-banner-headline-font-decoration': 'none',
    '--top-banner-headline-font-weight': 'bold',
    // top-banner-subheadline
    '--top-banner-subheadline-font-color': '#000000',
    '--top-banner-subheadline-font-style': 'normal',
    '--top-banner-subheadline-font-decoration': 'none',
    '--top-banner-subheadline-font-weight': 'normal',  
    // top-banner-paragraph
    '--top-banner-paragraph-font-color': '#000000',
    '--top-banner-paragraph-font-style': 'normal',
    '--top-banner-paragraph-font-decoration': 'none',
    '--top-banner-paragraph-font-weight': 'normal',
    // top-banner-img
    '--top-banner-img-url': 'url(./assets/images/themes/calm-relaxing-2/calm-relaxing-2-header-background.jpg)',
    '--top-banner-bg-color': 'rgba(218,241,241,0.94)',

    // global-nav 
    '--nav-bg-color': '#0b7575',
    '--nav-font-color': '#ffffff',
    '--nav-font-style': 'normal',
    '--nav-font-decoration': 'none',
    '--nav-font-weight': 'normal',

    // cards 
    '--card-bg-color': '#ffffff',
    '--card-font-color': '#00cec9',
    '--card-font-style': 'normal',
    '--card-font-decoration': 'none',
    '--card-font-weight': 'bold',

    // additional
    '--additional-color': '#00cec9',
    '--additional-bg-color': '#81ecec',

    /* buttons */
    '--btn-font-style': 'normal',
    '--btn-font-decoration': 'none',
    '--btn-font-weight': 'normal',
    // button-primary
    '--btn-primary-main-color': '#81ecec',
    '--btn-primary-additional-color': '#0b7575',
    // button-secondary
    '--btn-secondary-main-color': '#0b7575',
    '--btn-secondary-additional-color': '#ffffff',
    // button-success
    '--btn-success-main-color': 'rgba(255,255,255,0)',
    '--btn-success-additional-color': '#00b894',

    // video-border
    '--video-border': '#81ecec',

    // badge
    '--badge-font-color': '#00cec9',
    '--badge-bg-color': '#FFFFFF',

    /* Footer */
    // footer-banner
    '--footer-banner-img-url': 'url(./assets/images/themes/calm-relaxing-2/calm-relaxing-2-footer-background.jpeg)',
    '--footer-banner-additional-img-url': './assets/images/themes/calm-relaxing-2/calm-relaxing-2-footer.png',
    '--footer-banner-bg-color': '#81ecec',
    // footer-headline
    '--footer-headline-font-color': '#00cec9',
    '--footer-headline-font-style': 'normal',
    '--footer-headline-font-decoration': 'none',
    '--footer-headline-font-weight': 'bold',
    // footer-paragraph
    '--footer-paragraph-font-color': '#000000',
    '--footer-paragraph-font-style': 'normal',
    '--footer-paragraph-font-decoration': 'none',
    '--footer-paragraph-font-weight': 'normal',

    //Preset images
    '--img-logo-login':     './assets/images/themes/calm-relaxing-2/calm-relaxing-2-logo-white.png',
    '--img-logo-dashboard': './assets/images/themes/calm-relaxing-2/calm-relaxing-2-logo-white.png',
    '--img-favicon':        './assets/images/themes/calm-relaxing-2/calm-relaxing-2-favicon.webp',
    '--img-login-desktop':  './assets/images/themes/calm-relaxing-2/calm-relaxing-2-login.webp',
    '--img-login-mobile':   './assets/images/themes/calm-relaxing-2/calm-relaxing-2-login-mobile.webp',
    '--img-user-avatar':    './assets/images/themes/calm-relaxing-2/calm-relaxing-2-avatar.png',
    '--img-shoutout-box':   './assets/images/themes/calm-relaxing-2/calm-relaxing-2-shoutout.png',
    '--img-cml-wrp':        './assets/images/themes/calm-relaxing-2/calm-relaxing-2-cml.jpg'
  }
};

export const high_energy: iTheme = {
  name: 'high-energy',
  properties: {
    /*** Global ***/ 
    '--headline-font-family': '\'Crete Round\', serif',
    '--subheadline-font-family': '\'Crete Round\', serif',
    '--paragraph-font-family': '\'Open Sans\', sans-serif',
    '--shadow-color': 'rgba(168,175, 196, 0.5)',
    '--link-font-color': '#000000',

    /*** Local ***/ 
    // main bg
    '--main-bg-color': '#F0F3F4',

    /* General text */
    // headlines
    '--headline-font-color': '#e74c3c',
    '--headline-font-style': 'normal',
    '--headline-font-decoration': 'none',
    '--headline-font-weight': 'normal',
    // subheadlines
    '--subheadline-font-color': '#ff1616',
    '--subheadline-font-style': 'normal',
    '--subheadline-font-decoration': 'none',
    '--subheadline-font-weight': 'normal',
    // paragraphs
    '--paragraph-font-color': '#4A4A4A',
    '--paragraph-font-style': 'normal',
    '--paragraph-font-decoration': 'none',
    '--paragraph-font-weight': 'normal',

    /* top-banner */
    // top-banner-global
    // '--top-banner-container-min-height': '550px',
    '--top-banner-container-min-height': 'auto',
    // top-banner-headline
    '--top-banner-headline-font-color': '#000000',
    '--top-banner-headline-font-style': 'normal',
    '--top-banner-headline-font-decoration': 'none',
    '--top-banner-headline-font-weight': 'bold',
    // top-banner-subheadline
    '--top-banner-subheadline-font-color': '#ff1616',
    '--top-banner-subheadline-font-style': 'normal',
    '--top-banner-subheadline-font-decoration': 'none',
    '--top-banner-subheadline-font-weight': 'normal',  
    // top-banner-paragraph
    '--top-banner-paragraph-font-color': '#000000',
    '--top-banner-paragraph-font-style': 'normal',
    '--top-banner-paragraph-font-decoration': 'none',
    '--top-banner-paragraph-font-weight': 'bold',
    // top-banner-img
    '--top-banner-img-url': 'url(./assets/images/themes/high-energy/high-energy-background-header.jpg)',
    '--top-banner-bg-color': 'rgba(255,255,255,0.66)',

    // global-nav 
    '--nav-bg-color': '#FFFFFF',
    '--nav-font-color': '#ff1616',
    '--nav-font-style': 'normal',
    '--nav-font-decoration': 'none',
    '--nav-font-weight': 'bold',

    // cards 
    '--card-bg-color': '#ffffff',
    '--card-font-color': '#ff1616',
    '--card-font-style': 'normal',
    '--card-font-decoration': 'none',
    '--card-font-weight': 'normal',

    // additional
    '--additional-color': '#ff1616',
    '--additional-bg-color': '#ff1616',

    /* buttons */
    '--btn-font-style': 'normal',
    '--btn-font-decoration': 'none',
    '--btn-font-weight': 'bold',
    // button-primary
    '--btn-primary-main-color': '#ff1616',
    '--btn-primary-additional-color': '#ffffff',
    // button-secondary
    '--btn-secondary-main-color': '#ff1616',
    '--btn-secondary-additional-color': '#ffffff',
    // button-success
    '--btn-success-main-color': '#2ecc71',
    '--btn-success-additional-color': '#ffffff',

    // video-border
    '--video-border': '#ff1616',

    // badge
    '--badge-font-color': '#ffffff',
    '--badge-bg-color': '#ff1616',

    /* Footer */
    // footer-banner
    '--footer-banner-img-url': 'url(./assets/images/themes/high-energy/high-energy-background-footer.jpg)',
    '--footer-banner-additional-img-url': './assets/images/themes/high-energy/high-energy-footer-img.jpg',
    '--footer-banner-bg-color': 'rgba(228,201,198,0.05)',
    // footer-headline
    '--footer-headline-font-color': '#ffffff',
    '--footer-headline-font-style': 'normal',
    '--footer-headline-font-decoration': 'none',
    '--footer-headline-font-weight': 'bold',
    // footer-paragraph
    '--footer-paragraph-font-color': '#ffffff',
    '--footer-paragraph-font-style': 'normal',
    '--footer-paragraph-font-decoration': 'none',
    '--footer-paragraph-font-weight': 'normal',

    //Preset images
    '--img-logo-login':     './assets/images/themes/high-energy/high-energy-logo-red.png',
    '--img-logo-dashboard': './assets/images/themes/high-energy/high-energy-logo-big-red.webp',
    '--img-favicon':        './assets/images/themes/high-energy/high-energy-favicon.png',
    '--img-login-desktop':  './assets/images/themes/high-energy/high-energy-login-bg.png',
    '--img-login-mobile':   './assets/images/themes/high-energy/high-energy-login-mobile.webp',
    '--img-user-avatar':    './assets/images/themes/high-energy/high-energy-user-avatar.png',
    '--img-shoutout-box':   './assets/images/themes/high-energy/high-energy-shoutout.webp',
    '--img-cml-wrp':        './assets/images/themes/high-energy/high-energy-cml.jpg'
  }
};


export const themeList: iTheme[] = [main, dark_mint, light_red, progressive_light, light, vip_jakob, vip_david, 
                                   vip_fitness, vip_baier, vip_energy, vip_jiufit, vip_dietrich, vip_kientzl, vip_kogler, 
                                   vip_kogler_kurse, vip_lobermeyer, vip_daniela, business_classic,
                                   business_elegant, nature_energy, calm_relaxing, calm_relaxing_2, high_energy ];
