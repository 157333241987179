import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { UserService } from "./user/user.service";
import { HttpRequestService } from "./http-request.service";
import { WebSocketSubject, webSocket } from "rxjs/webSocket";
import { environment } from "environments/environment";

@Injectable()
export class NotificationsService {
  constructor(
    private _userService: UserService,
    private _httpService: HttpRequestService
  ) { }
  /**
   * Custom post request
   * @param reqURL
   * @param bodyString
   * @param requestOptions
   * @returns {any}
   */

  getWithToken(
    url: string,
    workerIgnore: boolean = false,
    limit = ""
  ): Observable<any> {
    let reqUrl = `${url}?token=${this._userService.token}${limit}`;
    if (workerIgnore) {
      reqUrl += "&ngsw-bypass=true";
    }
    return this._httpService.notificationsApiGet(reqUrl);
  }

  set(endPoint: string, requestOptions?: any): Observable<any> {
    let requestTokenParam = {
      token: this._userService.token,
      ...requestOptions,
    };
    const bodyString = JSON.stringify(requestTokenParam);
    return this._httpService.notificationsApiPost(endPoint, bodyString);
  }

  markAsRead(endPoint: string, requestOptions?: any): Observable<any> {
    let requestTokenParam = {
      token: this._userService.token,
      ...requestOptions,
    };
    const bodyString = JSON.stringify(requestTokenParam);
    return this._httpService.notificationsApiPost(endPoint, bodyString);
  }

  getNotificationsInApp(): Observable<any> {
    const url = `wss:${environment.notificationWssUrl}/notifications_api/v1/notifications/monitor?token=${this._userService.token}`;
    const subject: WebSocketSubject<any> = webSocket(url);
    return subject;
  }

  getEmailContent(value: string, langCode: string): Observable<any> {
    return this._httpService.notificationsApiGet(
      `template/get?component_name=notifications-default-customer-email-templates&template_code=${value}&language=${langCode}`
    );
  }
}
