import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { iEntityFilterGetherParams, iEntityFilterParams } from '../interfaces/entity-params';
import { CoreEntityService } from './_core.entity.service';
import { iUser, iPasswordChanges, iTrialUser, iPortal } from '../interfaces/user';
import { iOrder } from '../interfaces/order';
import { iEmailTest } from '../interfaces/email';
import { iCommunitySettings, iCoursesToCommunity } from '@coreinterfaces/community';

@Injectable()
export class EntityService {

  constructor(private _coreEntityService: CoreEntityService) {}

  /*****************************************************  GETTERS  ****************************************************/

  getWithNoAuth(url: string): Observable<any> {
    return this._coreEntityService.getWithNoAuth(url);
  }

  getWithToken(url: string): Observable<any> {
    return this._coreEntityService.getWithToken(url);
  }

  postWithNoAuth(url: string, data: any): Observable<any> {
    return this._coreEntityService.postWithNoAuth(url, data);
  }

  get(entityType: string, explicityRequest?: any, requestOptions?: any): Observable<any> {
    return this._coreEntityService.get(entityType, explicityRequest, requestOptions);
  }

  getEntity(entityType: string, explicityRequest?: iEntityFilterParams, requestOptions?: any): Observable<any> {
    return this._coreEntityService.getEntity(entityType, explicityRequest, requestOptions);
  }

  getPortalEntity(entityType: string, explicityRequest?: iEntityFilterParams, requestOptions?: any): Observable<any> {
    return this._coreEntityService.getPortalEntity(entityType, explicityRequest, requestOptions);
  }

  getEntityInfoWithNoAuth(entityType: string, explicityRequest?: iEntityFilterParams, requestOptions?: any): Observable<any> {
    return this._coreEntityService.getEntityInfoWithNoAuth(entityType, explicityRequest, requestOptions);
  }

  getEntityInfo(entityType: string, explicityRequest?: iEntityFilterParams, requestOptions?: any): Observable<any> {
    return this._coreEntityService.getEntityInfo(entityType, explicityRequest, requestOptions);
  }

  getImageUrl(imagePart: string): string {
    return this._coreEntityService.getImageUrl(imagePart);
  }

  getPaidStatus(id: number, portalMemberId?: number, requestOptions?: any): Observable<any> {
    return this._coreEntityService.getPaidStatus(id, portalMemberId, requestOptions);
  }

  getCustomerCourseAndModulesProgress(courseIds: Array<number>, portalMemberId: number): Observable<any> {
    return this._coreEntityService.getCustomerCourseAndModulesProgress(courseIds, portalMemberId);
  }

  getLessonAndModulesAccess(customerId: number, courseIds: Array<number>, limit?: number): Observable<any> {
    return this._coreEntityService.getLessonAndModulesAccess(customerId, courseIds, limit);
  }

  getCoursesModulesAndLessonsCount(courseIds: Array<number>): Observable<any> {
    return this._coreEntityService.getCoursesModulesAndLessonsCount(courseIds);
  }

  getVideoAnimatedPreview(fileHandle: string | number): Observable<any> {
    return this._coreEntityService.getVideoAnimatedPreview(fileHandle);
  }

  getGetherEntity(explicityRequest?: iEntityFilterGetherParams, requestOptions?: any): Observable<any> {
    return this._coreEntityService.getGetherEntity(explicityRequest, requestOptions);
  }

  getherEntityRequest(explicityRequest?: iEntityFilterGetherParams, requestOptions?: any): Observable<any> {
    return this._coreEntityService.getherEntityRequest(explicityRequest, requestOptions);
  }

  /*****************************************************  ACTIONS  ****************************************************/

  countEntity(entityType: string, explicityRequest?: iEntityFilterParams, requestOptions?: any): Observable<any> {
    return this._coreEntityService.countEntity(entityType, explicityRequest, requestOptions);
  }

  countPortalEntity(entityType: string, explicityRequest?: iEntityFilterParams, requestOptions?: any): Observable<any> {
    return this._coreEntityService.countPortalEntity(entityType, explicityRequest, requestOptions);
  }

  deleteEntity(entityType: string, value: number[] | number, requestOptions?: any): Observable<any> {
    let numberToArray: number[] = [];
    if (!Array.isArray(value)) {
      numberToArray.push(value);
    } else {
      numberToArray = value as number[];
    }
    return this._coreEntityService.deleteEntity(entityType, numberToArray, requestOptions);
  }

  oneClickImport(entityType: string, id: number): Observable<any> {
    return this._coreEntityService.oneClickImport(entityType, id);
  }

  delete(entityType: string, requestParams: iEntityFilterParams, requestOptions?: any): Observable<any> {
    return this._coreEntityService.delete(entityType, requestParams, requestOptions);
  }

  deleteCAccount(entityType: string, requestOptions?: any): Observable<any> {
    return this._coreEntityService.deleteCAccount(entityType, requestOptions);
  }

  addEntity(entityType: string, entity: any, requestOptions?: any): Observable<any> {
    return this._coreEntityService.addEntity(entityType, entity, requestOptions);
  }

  resetEntity(entityType: string, entity: number, requestOptions?: any): Observable<any> {
    return this._coreEntityService.resetEntity(entityType, entity, requestOptions);
  }

  addPortalEntity(entityType: string, entity: any, requestOptions?: any): Observable<any> {
    return this._coreEntityService.addPortalEntity(entityType, entity, requestOptions);
  }

  update(entityType: string, entity: any, requestOptions?: any): Observable<any> {
    return this._coreEntityService.update(entityType, entity, requestOptions);
  }

  updateEntity(entityType: string, entity: any, requestOptions?: any): Observable<any> {
    return this._coreEntityService.updateEntity(entityType, entity, requestOptions);
  }

  updatePortalEntity(entityType: string, entity: any, requestOptions?: any): Observable<any> {
    return this._coreEntityService.updatePortalEntity(entityType, entity, requestOptions);
  }

  upsertEntity(entityType: string, entities: any[] | any, requestOptions?: any): Observable<any> {
    return this._coreEntityService.upsertEntity(entityType, entities, requestOptions);
  }

  login(loginData: iUser, requestOptions?: any): Observable<any> {
    return this._coreEntityService.login(loginData, requestOptions);
  }

  loginAsCC(id: number, requestOptions?: any): Observable<any> {
    return this._coreEntityService.loginAsCC(id, requestOptions);
  }

  logout(): Observable<any> {
    return this._coreEntityService.logout();
  }

  changePassword(changes: iPasswordChanges, requestOptions?: any): Observable<any> {
    return this._coreEntityService.changePassword(changes, requestOptions);
  }

  registerSingle(registrationData: iUser, requestOptions?: any): Observable<any> {
    return this._coreEntityService.registerSingle(registrationData, requestOptions);
	}

	registerDouble(registrationData: iUser, requestOptions?: any): Observable<any> {
    return this._coreEntityService.registerDouble(registrationData, requestOptions);
	}

	registerActivate(registrationData: {[key: string]: string}): Observable<any> {
    return this._coreEntityService.registerActivate(registrationData);
	}

  prolongate(token?: string, requestOptions?: any): Observable<any> {
    return this._coreEntityService.prolongate(token, requestOptions);
  }

  checkDomain(newDomain: string, oldDomain: string, requestOptions?: any): Observable<any> {
    return this._coreEntityService.checkDomain(newDomain, oldDomain, requestOptions);
  }

  saveCustomDomain(customDomain: string, portalId: number, requestOptions?: any): Observable<any> {
    return this._coreEntityService.saveCustomDomain(customDomain, portalId, requestOptions);
  }

  sendHelpMail(message: string, subject?: string, requestOptions?: any): Observable<any> {
    return this._coreEntityService.sendHelpMail(message, subject, requestOptions);
  }

  addCourseToLibrary(type: 'free' | 'preview', courseHash: string, requestOptions?: any): Observable<any> {
    return this._coreEntityService.addToCourseLibrary(type, courseHash, requestOptions)
  }

  addFreeCourseToCustomer(data: any, requestOptions?: any): Observable<any> {
    return this._coreEntityService.addFreeCourseToCustomer(data, requestOptions)
  }

  addFreeCourseToCustomerWithDoubleOptin(data: any, requestOptions?: any): Observable<any> {
    return this._coreEntityService.addFreeCourseToCustomerWithDoubleOptin(data, requestOptions)
  }

  createOrderByCC(order: iOrder, requestOptions?: any): Observable<any> {
    return this._coreEntityService.createOrderByCC(order, requestOptions);
  }

  generatePassword(id: number, requestOptions?: any): Observable<any> {
    return this._coreEntityService.generatePassword(id, requestOptions);
  }

  copyToTheEntity(copyEntityId: number, newEntitysId: Array<number>, entityName: string): Observable<any> {
    return this._coreEntityService.copyToTheEntity(copyEntityId, newEntitysId, entityName);
  }

  setAccessToUser(entityType: string, values: any): Observable<any> {
    return this._coreEntityService.setAccessToUser(entityType, values);
  }

  setCourseActivity(courseId: number, courseActive: boolean): Observable<any> {
    return this._coreEntityService.setCourseActivity(courseId, courseActive);
  }

  cloneCMLEntity(entityName: string, entityId: number, selectedEntitys: any, isDuplicate: boolean = true): Observable<any> {
    return this._coreEntityService.cloneCMLEntity(entityName, entityId, selectedEntitys, isDuplicate);
  }

  toggleUpdateMessage(messageId: number, isEnable: boolean): Observable<any> {
    return this._coreEntityService.toggleUpdateMessage(messageId, isEnable)
  }

  registerTrialPortal(trialUserData: iTrialUser): Observable<any> {
    return this._coreEntityService.registerTrialPortal(trialUserData);
  }

  activateUser(id: number): Observable<any> {
    return this._coreEntityService.activateUser(id);
  }

  /************************************************* leads ***********************************************/

  createLead(data: any, requestOptions?: any): Observable<any> {
    return this._coreEntityService.createLead(data, requestOptions)
  }

  createActivation(data: any, requestOptions?: any): Observable<any> {
    return this._coreEntityService.createActivation(data, requestOptions)
  }

  activateLead(data: any, requestOptions?: any): Observable<any> {
    return this._coreEntityService.activateLead(data, requestOptions)
  }

  /************************************************* OTHER ***********************************************/

  abstractAuthorizedPostRequest(uri: string, data?: any, requestOptions?: any): Observable<any> {
    return this._coreEntityService.abstractAuthorizedPostRequest(uri, data, requestOptions);
  }

  /************************************************* SMTP ***********************************************/

  setSmtpSettings(entity: any, requestOptions?: any): Observable<any> {
    return this._coreEntityService.setSmtpSettings(entity, requestOptions);
  }

  /************************************************* PAYMENT ***********************************************/

  addApiKey(marketPlaceObj: any, requestOptions?: any): Observable<any> {
    return this._coreEntityService.addApiKey(marketPlaceObj, requestOptions);
  }

  generateCopecartCredentials(requestOptions?: any): Observable<any> {
    return this._coreEntityService.generateCopecartCredentials(requestOptions);
  }

  generateFapiCredentials(requestOptions?: any): Observable<any> {
    return this._coreEntityService.generateFapiCredentials(requestOptions);
  }

  generateZapierApiKey(requestOptions?: any): Observable<any> {
    return this._coreEntityService.generateZapierApiKey(requestOptions);
  }

  /************************************************* EMAIL TEST ***********************************************/

  emailTest(entityType: string, entity: iEmailTest, requestOptions?: any): Observable<any> {
    return this._coreEntityService.emailTest(entityType, entity, requestOptions);
  }

  /************************************************* ONE CLICK AFFILATE ***********************************************/

  acceptAffiliate(userPortalId: number, requestOptions?: any): Observable<any> {
    return this._coreEntityService.acceptAffiliate(userPortalId, requestOptions);
  }

  importWhiteLabelCourse(userPortalId: number, courseId: number): Observable<any> {
    return this._coreEntityService.importWhiteLabelCourse(userPortalId, courseId);
  }

  importWhiteLabelLanding(userPortalId: number, landingId: number): Observable<any> {
    return this._coreEntityService.importWhiteLabelLanding(userPortalId, landingId);
  }

  /************************************************* EMAIL CHECK ***********************************************/

  checkEmailsTemplates(entity: any, requestOptions?: any): Observable<any> {
    return this._coreEntityService.checkEmailsTemplates(entity, requestOptions);
  }

  /************************************************* QUIZ ***********************************************/

  startNewQuizPass(lessonId: number): Observable<any> {
    return this._coreEntityService.startNewQuizPass(lessonId);
  }

  getQuizVerionByTimestamp(explicityRequest: iEntityFilterParams): Observable<any> {
    return this._coreEntityService.getQuizVerionByTimestamp(explicityRequest);
  }

  getQuizAccessByCourseIds(userId: number, courseId: number): Observable<any> {
    return this._coreEntityService.getQuizAccessByCourseIds(userId, courseId);
  }

  getExistQuizPassing(filters: any): Observable<any> {
    return this._coreEntityService.getExistQuizPassing(filters);
  }

  getQuizQuestion(assessmentId: number): Observable<any> {
    return this._coreEntityService.getQuizQuestion(assessmentId);
  }

  getQuizResults(assessmentId: number): Observable<any> {
    return this._coreEntityService.getQuizResults(assessmentId);
  }

  saveQuizAnswers(assessmentId: number, answers: Object): Observable<any> {
    return this._coreEntityService.saveQuizAnswers(assessmentId, answers);
  }

  getQuizAssesments(filters: any): Observable<any> {
    return this._coreEntityService.getQuizAssesments(filters);
  }

  /************************************************* COMMUNITY ***********************************************/

  getCommunityTypes(): Observable<any> {
    return this._coreEntityService.getCommunityTypes();
  }

  getCommunitySettings(): Observable<any> {
    return this._coreEntityService.getCommunitySettings();
  }

  getCommunityDisplayNameSettings(): Observable<any> {
    return this._coreEntityService.getCommunityDisplayNameSettings();
  }

  updateCommunitySettings(data: iCommunitySettings): Observable<any> {
    return this._coreEntityService.updateCommunitySettings(data);
  }

  updateBindedCourseList(communityId: number, courseIds: number[]): Observable<iCoursesToCommunity> {
    return this._coreEntityService.updateBindedCourseList(communityId, courseIds);
  }

  /************************************************* TRIAL USER ***********************************************/

  updateTrialUserTime(portal_id: number, new_date: number): Observable<any> {
    return this._coreEntityService.updateTrialUserTime(portal_id, new_date);
  }

  /********************************************** CC VERIFICATION ********************************************************/

	contentCreatorVerify(verificationData: {[key: string]: string}): Observable<any> {
    return this._coreEntityService.contentCreatorVerify(verificationData);
	}

  resendVerificationEmail(portalData: {portal_id: number, token: string}): Observable<any> {
    return this._coreEntityService.resendVerificationEmail(portalData);
  }

  rootVerifyContentCreator(portalData: {portal_id: number, token: string}): Observable<any> {
    return this._coreEntityService.rootVerifyContentCreator(portalData);
  }

  /******************************************* RECALCULATE COURSE ACCESS ***************************************/

  recalculateCourseAccess(courseId: number): Observable<any> {
    return this._coreEntityService.recalculateCourseAccess(courseId);
  }

  /********************************************** VIDEO ********************************************************/

  setVideoStaticPreview(videoFileHandle: string, previewFileId: string): Observable<any> {
    return this._coreEntityService.setVideoStaticPreview(videoFileHandle, previewFileId);
  }

  resetVideoStaticPreview(videoFileHandle: string, previewFrameSecond?: number): Observable<any> {
    return this._coreEntityService.resetVideoStaticPreview(videoFileHandle, previewFrameSecond);
  }
}
