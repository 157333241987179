import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

interface iNeedsCacheUrl {
  [key: string]: boolean;
}

const maxAge = 1 * 60 * 60 * 1000;

@Injectable()
export class RequestCache  {

  private _needsToCacheMap: iNeedsCacheUrl = {};

  cache = new Map();

  constructor() {}

  getNeedsToCache(type: string): boolean { return this._needsToCacheMap[type]; }

  setNeedsToCache(type: string, isNeed: boolean) { this._needsToCacheMap[type] = isNeed; }

  clearAllNeeds(): void { this._needsToCacheMap = {} }

  get(req: HttpRequest<any>, type: string): HttpResponse<any> | undefined {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);
    if (!this.getNeedsToCache(type) || !cached) return undefined;
    return cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>, type: string): void {
    this.setNeedsToCache(type, true);
    const url = req.url;
    const entry = { url, response, lastRead: Date.now() };
    this.cache.set(url, entry);
    const expired = Date.now() - maxAge;
    this.cache.forEach(expiredEntry => {
      if (expiredEntry.lastRead < expired) {
        this.cache.delete(expiredEntry.url);
      }
    });
  }

}
