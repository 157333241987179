import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {

  @HostBinding('class') classes: string = 'h-100';

  constructor() { }

  ngOnInit() { }

}
