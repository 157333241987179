import { Observable, forkJoin, of, isObservable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators';
import { EntityService } from './entity.service';
import { UserService } from './user/user.service';
import { iEntityFilterAttribute, iEntityFilterParams } from '../interfaces/entity-params';
import { iConfigSet, iGeneralSettings } from '../interfaces/settings';
import { BehaviorSubject } from 'rxjs/Rx';

@Injectable()
export class SettingsService {

  settingsInfo: iGeneralSettings;

  public _loadedData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public loadedDataEmitter: Observable<any> = this._loadedData.asObservable();

  defaultRequestParameters: iEntityFilterParams = {
    columns: ['**'],
    limit: 1,
    offset: 0,
    filter_by: [],
    search_by: []
  };

  defaultEntityTypeList = [
    'content_login_page_settings',
    'content_homepage_settings',
    'content_design_settings',
    'settings_payment',
    'settings_analytics',
    'content_email_settings',
    'settings_imprint_gdrp',
    'affiliate_product_page_settings',
    'community_settings',
    'portal_to_content_design_template'
  ];

  constructor(private _entityService: EntityService,
              private _userService: UserService) {}

  getSettingsInfo() {
    return this.settingsInfo;
  }

  initSet(entityTypeList?: string[]) {
    this.defaultRequestParameters.filter_by = [
      { attribute: 'portal_id', operator: '=', value: this._userService.portal_id }
    ];
    let requestParameters: iEntityFilterParams = this.defaultRequestParameters;

    if (entityTypeList) {
      let stashedSettings = {...this.getSettingsInfo()};
      this.setGeneralInfo(stashedSettings, entityTypeList)
    } else {
      this._entityService.getEntityInfoWithNoAuth('content_settings_selected', requestParameters).subscribe(
        res => {
          this.handleInitLoad(res).subscribe(result => {
            this.setGeneralInfo(result, entityTypeList)
          });
        }
      );
    }

  }

  /**
   * if not exists: Add and return added empty observed row
   *
   *
   * @param {iConfigSet[]} respArray
   * @returns {any}
   */
  handleInitLoad(respArray?: iConfigSet[]): Observable<any> {
    const defaultRow: any = {
      content_design_settings_id: null,
      content_login_page_settings_id: null,
      content_homepage_settings_id: null,
      portal_id: this._userService.portal_id
    };


    return (respArray && respArray.length > 0) ? of(respArray[0]) : 
      this._entityService.addEntity('content_settings_selected', defaultRow).pipe(
        map((response: any) => {
          defaultRow.portal_id = response;
          return defaultRow;
        })
      )
    ;
  }

  setGeneralInfo(result: iGeneralSettings, entityTypeList?: string[]) {
    this.settingsInfo = result;
    const defaultRow = { portal_id: this._userService.portal_id };
    let observables$ = [];
    let typeList = entityTypeList || this.defaultEntityTypeList;
    typeList.forEach(entityType => {
      let params = this.defaultRequestParameters;
      if(entityType === 'portal_to_content_design_template') {
        params.filter_by = [{attribute: 'is_active', value: true}]
      } else {
        params.filter_by = [];
      }
      observables$.push(this.generalInitEntity(entityType, params, defaultRow));
    });
    if (!entityTypeList && this._userService.getRole() !== 'customer' && this._userService.getRole() !== 'hidden_customer') {
      observables$.push(this.generalInitEntity('portal', { columns: ['marketplace_bindings.credentials', 'marketplace_bindings.marketplace_id', 'marketplace_bindings.is_active', 'custom_domain_enabled', 'custom_domain'] }, defaultRow));
    }
    const combined = forkJoin(observables$);
    combined.subscribe(
      resultResponse => {
        resultResponse.forEach((item: Observable<any>) => {
          item.subscribe();
        });
        this._loadedData.next(this.settingsInfo);
      }
    );
  }

  generalInitEntity(entityName: string, reqFilter: any, defaultRow: any): Observable<any>  {
    return this._entityService.getEntityInfoWithNoAuth(entityName, reqFilter).pipe(
      map((existingData: any) => {
        return this.generalInitHandler(existingData, entityName, reqFilter, defaultRow).pipe(
          map((result: any) => {     
            this.settingsInfo[entityName + '_id'] = result;
          }
        ))
      }));
  }

  generalInitHandler(existingData: any, entityName: string, reqFilter: iEntityFilterAttribute, defaultRow: any): Observable<any> {
    return (existingData && existingData.length > 0) ?
      of(existingData[0]) :
      this._entityService.addEntity(entityName, defaultRow);
  }
}
