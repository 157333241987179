import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './user/user.service';

@Injectable()
export class TrialPeriodService {

	constructor(private _userService: UserService,
              private _sanitizer: DomSanitizer) {	
	}

  updateDigistoreURL(email?: string, firstName?: string, lastName?: string): SafeResourceUrl {
    email = email.replace('+', '%2B');
    let productID: string;
    const langCode: string = this._userService.portal_lang_code;
    switch (langCode) {
      case 'de':
        productID = '416282';
      break;
      case 'mk':
      case 'hu':
      case 'en':
        productID = '356923';
      break;
      case 'cz':
      case 'sk':
        productID = '438159';
      break;
    }
    const url = `https://www.digistore24.com/product/${productID}?email=${email}&first_name=${firstName}&last_name=${lastName}`;

    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}