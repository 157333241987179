import { RouteReuseStrategy } from '@angular/router/';
import { ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

export class CacheRouteReuseStrategy implements RouteReuseStrategy {

    storedRouteHandles = new Map<string, DetachedRouteHandle>();

    allowRetriveCache = {
        'users': true
    };

    shouldReuseRouteMap = new Map([
        ['user-passport', 'users']
    ])

    isShouldReuseRoute = true;

    previousRouteKey = '';

    shouldReuseRoute(before: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        const prevPageKey = this.getKey(before);
        const currPageKey = this.getKey(curr);

        if (this.shouldReuseRouteMap.get(prevPageKey) === currPageKey) {
            this.allowRetriveCache[currPageKey] = this.isShouldReuseRoute;
        }

        this.previousRouteKey = prevPageKey;

        return before.routeConfig === curr.routeConfig;
    }

    /************************************************* GET CACHE ROUTE ***********************************************/

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        this.isShouldReuseRoute = true;
        
        const key = this.getKey(route);
        if (this.allowRetriveCache[key]) {
            this.allowRetriveCache[key] = false;
            return this.storedRouteHandles.has(this.getKey(route));
        }

        return false;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        if(this.previousRouteKey === '') {
            return false;
        }
        return this.storedRouteHandles.get(this.getKey(route)) as DetachedRouteHandle;
    }

    /************************************************* SET CACHE ROUTE ***********************************************/

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        const key = this.getKey(route);
        if (this.allowRetriveCache.hasOwnProperty(key)) {
            return true;
        }
        return false;
    }

    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
        this.storedRouteHandles.set(this.getKey(route), detachedTree);
    }

    /************************************************* GETTERS ***********************************************/

    private getKey(route: ActivatedRouteSnapshot): string {
        if (route.data?.key) {
            return route.data.key;
        }
        return '';
    }
}