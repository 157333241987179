import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { iLoginStartupData } from '@coreinterfaces/login-startup';
import { iDesignTemplate, iPortalToContentDesignTemplateBinding } from '@coreinterfaces/themes';
import { NewDesignSettingsService } from './new-design-settings.serivce';
import { StaticDataService } from './static-info.service';
import { ThemeService } from './theme.service';
import { Meta } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FileManagerService } from './file-manager.service';
import { CommunityActionService } from '@admincommunity/community-feed-view/community-action.service';

@UntilDestroy()
@Injectable()
export class LoginStartupService {

  completeLoad = false;
  loginStartupData: iLoginStartupData;

  settingsDesign: any;
  newDesignImages: any;

  constructor(private _entityService: EntityService,
              private _newDesignSettingsService: NewDesignSettingsService,
              private _staticDataService: StaticDataService,
              private _themeService: ThemeService,
              private _deviceService: DeviceDetectorService,
              private _fileManagerService: FileManagerService,
              private _metaTagService: Meta,
              private _communityActionService: CommunityActionService) {}

  getLoginStartupData(): void {
    this._entityService
        .get('portal/login_page/get')
        .pipe(untilDestroyed(this))
        .subscribe(
          resp => {
            if(resp) {
              this.loginStartupData = resp;
              this.completeLoad = true;
              this.setStartupDesignStaticData()
            }
          }
        )
  }

  setStartupDesignStaticData(): void {
    const consts = this._staticDataService.StaticData;
    this._newDesignSettingsService.setThemesCodeMap(consts.design_settings_code);

    const resolvedActiveDesignTemplate: iDesignTemplate = this._themeService.designTemplatesMap[
      this.loginStartupData?.portal_to_content_design_template?.content_design_template_id || 1
    ];
    let designCodeToActivate: string = (resolvedActiveDesignTemplate) ? resolvedActiveDesignTemplate.code : 'main';
    console.warn('designCodeToActivate', designCodeToActivate);

    // // design settings handler
    if(this.loginStartupData) {
      this._themeService.activateTheme(designCodeToActivate, this.loginStartupData?.portal_to_content_design_template?.custom_content_design);
      this.settingsDesign = this.loginStartupData.content_design_settings;
      this.newDesignImages = this.loginStartupData.portal_design_images;
      this.setFavicon();
      this._metaTagService.addTags([
        { name: "title", content: this.loginStartupData.content_login_page_settings.title as string },
        { name: "keywords", content: this.loginStartupData.content_login_page_settings.subtitle as string },
        { name: "description", content: this.loginStartupData.content_login_page_settings.description as string }
      ])
    }
  }

  /*************************************************  ***********************************************/

  // only for local use. SSR set favicon dynamic cuz fcking safari
  setFavicon(): void {
    const deviceInfo: any = this._deviceService.getDeviceInfo();
    let favicon: any;
    let link: any;
    let defaultThemeFavicon = 'assets/images/favicon/favicon-32x32.png';
    this._themeService.findActiveTheme();
    let presetThemeFavicon = this._themeService.themeMap.properties['--img-favicon'];
    if (presetThemeFavicon) defaultThemeFavicon = presetThemeFavicon;

    if (deviceInfo.browser === 'Safari') {
      link = document.querySelector("link[rel*='mask-icon']") || document.createElement('link');
    } else {
      link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.rel = 'icon';
    }
    
    if (this.newDesignImages && this.newDesignImages.favicon) {
      favicon = this._fileManagerService.getImageSrc(this.newDesignImages.favicon);
    } else {
      favicon = defaultThemeFavicon;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Load the custom favicon image onto the canvas
    const faviconImage = new Image();
    faviconImage.crossOrigin = 'Anonymous';
    faviconImage.src = favicon;
    faviconImage.onload = () => {
      canvas.width = faviconImage.width;
      canvas.height = faviconImage.height;
      ctx.drawImage(faviconImage, 0, 0);

      function drawOrClearDot(isNewNotify) {
        if (isNewNotify) {
          // Draw the red dot on the canvas
          const dotSize = Math.min(canvas.width, canvas.height) * 0.6;
          ctx.beginPath();
          ctx.arc(canvas.width - dotSize / 2, dotSize / 2, dotSize / 2, 0, 2 * Math.PI);
          ctx.fillStyle = '#ff5400'; // Change color as needed
          ctx.fill();
        } else {
          // Clear the canvas
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          // Redraw the original favicon
          ctx.drawImage(faviconImage, 0, 0);
        }

        // Convert canvas image data to data URL
        const dataUrl = canvas.toDataURL('image/png');

        link.rel = 'icon';
        link.type = 'image/png';
        link.href = dataUrl;

        // Remove existing favicon before appending the updated one
        const existingFavicon = document.querySelector('link[rel="icon"]');
        if (existingFavicon) {
          existingFavicon.remove();
        }
        document.head.appendChild(link);
      }
      this._communityActionService.$_isNewNotification.subscribe(drawOrClearDot);
    };
  }

}
