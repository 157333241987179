import { Injectable } from '@angular/core';

@Injectable()
export class VideoService {
  isVimeoLink(link: string): boolean {
    return link
    ? link.indexOf('vimeo.com') !== -1
    : false;
  }

  isYouTubeLink(link: string): boolean {
    return link
    ? link.indexOf('youtube.com') !== -1
    : false;
  }

  getYouTubeThumbnail(link: string) {
    const videoId = link.split('/embed/')[1];
    return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
  }

  getVimeoThumbnail(link: string) {
    const videoId = link.split('/video/')[1].split('?')[0];
    return `https://vumbnail.com/${videoId}.jpg`;
  }
}
