import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunityActionService {

  _leaveCommunity: Subject<any> = new Subject<any>();
  $leaveCommunity: Observable<any> = this._leaveCommunity.asObservable();
  _joinCommunity: Subject<any> = new Subject<any>();
  $_joinCommunity: Observable<any> = this._joinCommunity.asObservable();

  _isNewNotification: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $_isNewNotification: Observable<boolean> = this._isNewNotification.asObservable();

  joinCommmunity() {
    this._joinCommunity.next();
  }

  join(data: boolean): void {
    this._leaveCommunity.next(data);
  }

  newNotification(value: boolean) {
    this._isNewNotification.next(value);
  }
}
