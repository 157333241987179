import { Router } from "@angular/router";
import { iCourse } from "@coreinterfaces/course";

interface AppChannel {
  postMessage: (...args: any[]) => void
}


interface InAppPurchase {
  postMessage: (...args: any[]) => void
}

interface MobileAppWindow extends Window {
  AppChannel?: AppChannel
  InAppPurchase?: InAppPurchase
}

export class MobileAppService {

  static enabled = true;

  static get isEnabled() :boolean {
    if ( !MobileAppService.enabled ) {
    }
    return MobileAppService.enabled;
  }

  static setListener(router: Router): void {
    if ( !MobileAppService.isEnabled ) { return; }

    const navigationEventName = 'navigate';
    document.addEventListener( navigationEventName, (event: CustomEvent) => {
      if ( typeof event.detail?.path === 'string' ) {
        try {
          router.navigate([ event.detail.path ], { queryParams: event.detail?.params });
          document.getElementsByTagName('body')[0].style.overflow=null;
        } catch(e) { console.warn(`MobileAppService, navigationListener error: ${e}`); }
      }
    });

    document.addEventListener( 'community-offcanvas-visibility', (event: CustomEvent) => {
      if ( typeof event.detail?.action === 'string' ) {
        try {
          let OffcanvasBtn = document.getElementById("offcanvas-btn") as HTMLElement;
          switch( event.detail?.action ) {
            case 'toggle':
              OffcanvasBtn.click();
              break;
          }
        } catch(e) {}
      }
    });

    document.addEventListener( 'dashboard-offcanvas-visibility', (event: CustomEvent) => {
      if ( typeof event.detail?.action === 'string' ) {
        try {
          let OffcanvasBtn = document.getElementById("course-list-menu-btn") as HTMLElement;
          switch( event.detail?.action ) {
            case 'toggle':
              OffcanvasBtn.click();
              break;
          }
        } catch(e) {}
      }
    });
  }

  static get isMobileApp() :boolean {
    if ( !MobileAppService.isEnabled ) {
      return false;
    }
    return localStorage.getItem('is_mobile_app') === '1';
  }

  static get isMobileAppIos() :boolean {
    if ( !MobileAppService.isEnabled ) {
      return false;
    }
    return localStorage.getItem('platform') === 'ios';
  }
  
  static get isMobileAppAndroid() :boolean {
    if ( !MobileAppService.isEnabled ) {
      return false;
    }
    return localStorage.getItem('platform') === 'android';
  }

  static setIsMobileApp() :boolean {
    if ( !MobileAppService.isEnabled ) {
      return false;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const platform = urlParams.get('platform');
    
    if (platform === 'ios' || platform === 'undefined' || !platform) {
        localStorage.setItem('platform', 'ios');
    } else if (platform === 'android') {
      localStorage.setItem('platform', 'android');
    }
    if (urlParams.get('is_mobile_app') === '1') localStorage.setItem('is_mobile_app', '1');
  }

  static toCourseInfoPage(course: iCourse) {
    if ( !MobileAppService.isEnabled ) { return; }

    const otherCourse = Boolean(course.landing_id);

    const appMessage = JSON.stringify({
      action: 'open-url',
      tag: otherCourse ? 'other-course' : 'landing',
      url: otherCourse ? `/customer/other-course?course_id=${course.id}` : course.url
    });

    MobileAppService.notifyApp(appMessage);
  }

  static returnFromCourseInfoPage() {
    if ( !MobileAppService.isEnabled ) { return; }

    const appMessage = JSON.stringify({
      action: 'close'
    });

    MobileAppService.notifyApp(appMessage);
  }

  static notifyApp(json: string) {
    if ( !MobileAppService.isEnabled ) { return; }
    if ( (window as MobileAppWindow).AppChannel !== undefined ) {
      (window as MobileAppWindow).AppChannel.postMessage(json);
    }
  }

  static inAppPuchase(id: string) {
    if ( !MobileAppService.isEnabled ) { return; }
    if ( (window as MobileAppWindow).InAppPurchase !== undefined ) {
      (window as MobileAppWindow).InAppPurchase.postMessage(id);
    }
  }
}

