import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import 'rxjs/add/observable/of';
import { RequestCache } from '../services/cache.service';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {

  constructor(private _requestCache: RequestCache) {}

  private readonly urls: string[] = [
    'entity/language/get',
    'entity/payment_type/get',
    'entity/course_access_type/get',
    'entity/landing_type/get',
    'entity/content_email_template_type/get',
    'entity/mailing_settings_type/get',
    'entity/billing_type/get',
    'entity/user_status/get',
    'entity/user_registration_status/get',
    'entity/event_type/get'
  ];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.checkUrl(req)) {
      return next.handle(req);
    } else {
      const cachedResponse = this._requestCache.get(req, this.getFoundType(req));
      return (cachedResponse) ? Observable.of(cachedResponse) : this.sendRequest(req, next, this._requestCache);
    }
  }

  sendRequest(req: HttpRequest<any>, next: HttpHandler, _requestCache: RequestCache): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          _requestCache.put(req, event, this.getFoundType(req));
        }
      })
    );
  }

  /**
   * Check if request is silent.
   * @param {HttpRequest<any>} req
   * @returns {boolean}
   */
  private checkUrl(req: HttpRequest<any>): boolean {
    const requestUrl = req.url.toLowerCase();
    const found = this.urls.find((url) => requestUrl.includes(url) );
    return !!found;
  }

  private getFoundType(req: HttpRequest<any>): string | undefined {
    const url = req.url.toLowerCase();
    for (let i = 0; i < this.urls.length; i++) {
      if (url.includes(this.urls[i])) return this.urls[i];
    }
    return undefined;
  }
}
