import { Injectable } from '@angular/core';
import {
  iDesignTemplate,
  iDesignTemplateType,
  iPortalToContentDesignTemplateBinding,
} from '@coreinterfaces/themes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from 'environments/environment';
import { Observable, forkJoin } from 'rxjs';
import { UserService } from './user/user.service';
import { EntityService } from './entity.service';
import { ThemeService } from './theme.service';
import { ServerSideRenderingService } from './server-side-rendering.service';
import { FileManagerService } from './file-manager.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Meta } from '@angular/platform-browser';
import { SettingsService } from './settings.service';
import { NewDesignSettingsService } from './new-design-settings.serivce';
import { Router } from '@angular/router';
import { CommunityActionService } from '@admincommunity/community-feed-view/community-action.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DesignStartupService {
  constructor(
    private _router: Router,
    private _userService: UserService,
    private _entityService: EntityService,
    private _themeService: ThemeService,
    private _ssr: ServerSideRenderingService,
    private _fileManagerService: FileManagerService,
    private _deviceService: DeviceDetectorService,
    private _metaTagService: Meta,
    private _settingsService: SettingsService,
    private _newDesignSettingsService: NewDesignSettingsService,
    private _communityActionService: CommunityActionService
  ) {}

  getDesignInfo(): void {
    if (this._ssr.isBrowser) {
      // get and set design templates and language from db
      const contentDesignTemplates$: Observable<any> = this._entityService.getWithNoAuth(
        'entity/content_design_template/get'
      );
      const contentDesignTemplateTypes$: Observable<any> = this._entityService.getWithNoAuth(
        'entity/content_design_template_type/get'
      );
      const combinedSources: Observable<any> = forkJoin([
        contentDesignTemplates$,
        contentDesignTemplateTypes$,
      ]);
      combinedSources.pipe(untilDestroyed(this)).subscribe(
        ([templates, template_types]) => {
          let designTemplatesMap: { [key: number]: iDesignTemplate } = {};
          let designTemplatesTypesMap: { [key: number]: iDesignTemplateType } = {};

          templates.forEach((template) => (designTemplatesMap[template.id] = template));
          template_types.forEach((type) => (designTemplatesTypesMap[type.id] = type));

          this._themeService.designTemplatesMap = designTemplatesMap;
          this._themeService.designTemplateTypesMap = designTemplatesTypesMap;

          this.setDesignTemplate();
        },
        (error) => {
          if (
            !window.localStorage.getItem('portal') ||
            !window.location.href.includes(window.localStorage.getItem('portal')['domain'])
          ) {
            this._router.navigateByUrl('/login');
          }
          console.error(error);
        }
      );
    }
  }

  setDesignTemplate(): void {
    if (!environment.production) {
      if (this._ssr.isBrowser) {
        if (this._userService.portal_id) {
          this._settingsService.initSet();
        }
        this.designCombinedHandler();
      }
    } else {
      if (this._ssr.isBrowser) {
        this._entityService
          .checkDomain(window.location.hostname, window.location.hostname)
          .pipe(untilDestroyed(this))
          .subscribe(
            (success_response) => {
              if (success_response.done) {
                if (success_response.admin) {
                  this._themeService.activateTheme('main');
                  this.setFavicon();
                } else {
                  if (this._userService.portal_id) {
                    this._settingsService.initSet();
                  }
                  this.designCombinedHandler();
                }
              }
            },
            (error) => console.error(error)
          );
      }
    }
  }

  /*************************************************  ***********************************************/

  getPortalDesignTemplate$: Observable<any> = this._entityService.getEntity(
    'portal_to_content_design_template'
  );
  getContentDesignSettings$: Observable<any> = this._entityService.getEntity('content_design_settings');
  getContentLoginSettings$: Observable<any> = this._entityService.getEntity('content_login_page_settings');
  getNewDesignImages$: Observable<any>;
  getNewDesignThemesCode$: Observable<any> = this._newDesignSettingsService.getInfo(
    'design_settings_code',
    {}
  );

  settingsDesign: any;
  newDesignImages: any;

  designCombinedHandler(): void {
    this.getNewDesignImages$ = this._newDesignSettingsService.getInfo('portal_design_images');
    const combinedDesignStream$: Observable<any> = forkJoin([
      this.getPortalDesignTemplate$,
      this.getContentDesignSettings$,
      this.getContentLoginSettings$,
      this.getNewDesignImages$,
      this.getNewDesignThemesCode$,
    ]);
    combinedDesignStream$.pipe(untilDestroyed(this)).subscribe(
      ([bindings, design_settings, login_settings, newDesignImages, newDesignThemesCode]) => {
        this._newDesignSettingsService.setThemesCodeMap(newDesignThemesCode);
        // bindings handler
        const activeDesignTemplateBinding: iPortalToContentDesignTemplateBinding = bindings.filter(
          (binding) => binding.is_active
        )[0];
        const resolvedActiveDesignTemplate: iDesignTemplate =
          this._themeService.designTemplatesMap[activeDesignTemplateBinding?.content_design_template_id || 1];
        let designCodeToActivate: string = resolvedActiveDesignTemplate
          ? resolvedActiveDesignTemplate.code
          : 'main';
        console.warn('designCodeToActivate', designCodeToActivate);

        // design settings handler
        this._themeService.activateTheme(
          designCodeToActivate,
          activeDesignTemplateBinding?.custom_content_design
        );
        this.settingsDesign = design_settings[0];
        this.newDesignImages = newDesignImages[0];
        this.setFavicon();
        this._metaTagService.addTags([
          { name: 'title', content: login_settings[0].title },
          { name: 'keywords', content: login_settings[0].subtitle },
          { name: 'description', content: login_settings[0].description },
        ]);
      },
      (error) => console.error(error)
    );
  }

  /*************************************************  ***********************************************/

  //only for local use. SSR set favicon dynamic cuz fcking safari
  setFavicon(): void {
    const deviceInfo: any = this._deviceService.getDeviceInfo();
    let favicon: any;
    let link: any;
    let defaultThemeFavicon = 'assets/images/favicon/favicon-32x32.png';
    this._themeService.findActiveTheme();
    let presetThemeFavicon = this._themeService.themeMap.properties['--img-favicon'];
    if (presetThemeFavicon) defaultThemeFavicon = presetThemeFavicon;

    if (deviceInfo.browser === 'Safari') {
      link = document.querySelector("link[rel*='mask-icon']") || document.createElement('link');
    } else {
      link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.rel = 'icon';
    }

    if (this.newDesignImages && this.newDesignImages.favicon) {
      favicon = this._fileManagerService.getImageSrc(this.newDesignImages.favicon);
    } else {
      favicon = defaultThemeFavicon;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Load the custom favicon image onto the canvas
    const faviconImage = new Image();
    faviconImage.crossOrigin = 'Anonymous';
    faviconImage.src = favicon;
    faviconImage.onload = () => {
      canvas.width = faviconImage.width;
      canvas.height = faviconImage.height;
      ctx.drawImage(faviconImage, 0, 0);

      function drawOrClearDot(isNewNotify) {
        if (isNewNotify) {
          // Draw the red dot on the canvas
          const dotSize = Math.min(canvas.width, canvas.height) * 0.6;
          ctx.beginPath();
          ctx.arc(canvas.width - dotSize / 2, dotSize / 2, dotSize / 2, 0, 2 * Math.PI);
          ctx.fillStyle = '#ff5400'; // Change color as needed
          ctx.fill();
        } else {
          // Clear the canvas
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          // Redraw the original favicon
          ctx.drawImage(faviconImage, 0, 0);
        }

        // Convert canvas image data to data URL
        const dataUrl = canvas.toDataURL('image/png');

        link.rel = 'icon';
        link.type = 'image/png';
        link.href = dataUrl;

        // Remove existing favicon before appending the updated one
        const existingFavicon = document.querySelector('link[rel="icon"]');
        if (existingFavicon) {
          existingFavicon.remove();
        }
        document.head.appendChild(link);
      }
      this._communityActionService.$_isNewNotification.subscribe(drawOrClearDot);
    };
  }
}
