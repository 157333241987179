import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { iCommunityNameSettings, iCommunitySettings } from '@coreinterfaces/community';
import { BehaviorSubject, Observable } from 'rxjs';
import { EntityService } from './entity.service';

@UntilDestroy()
@Injectable()
export class CommunitySettingsService {

  private _namesSettings: iCommunityNameSettings[];
  private _communitySettings: iCommunitySettings;

  _updateCommunityAcivationSetting: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  $updateCommunityAcivationSetting: Observable<boolean> = this._updateCommunityAcivationSetting.asObservable();

  get namesSettings() {
    return this._namesSettings;
  }

  get communitySettings() {
    return this._communitySettings;
  }

  private _nameSettingsMap: Map<number, iCommunityNameSettings> = new Map();

  get nameSettingsMap() {
    return this._nameSettingsMap;
  }
  

  constructor(private _entityService: EntityService) { }

  getSettings(): void {
    this._entityService.getCommunitySettings().pipe(untilDestroyed(this)).subscribe(settings => {
      this._communitySettings = settings[0];
      this.updateCommunityAcivationSetting(this._communitySettings.is_active);
      this._namesSettings = this._communitySettings.element_settings;
      if(this._namesSettings.length > 0) {
          this.createNameSettingsMap();
      }
    });
  }

  createNameSettingsMap(): void {
    this._namesSettings.forEach(nameSetting => {
      this._nameSettingsMap.set(nameSetting.type_id, nameSetting);
    });
  }

  updateCommunityAcivationSetting(isActive: boolean) {
    this._updateCommunityAcivationSetting.next(isActive);
  }
}